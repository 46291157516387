import { jsx as _jsx } from "react/jsx-runtime";
import { AccountIds } from 'common/constants';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { FieldTypes } from '@/types';
const CompanyView = () => {
    // Can you help me conver the the above dataDesc to dataSpec, similar to AccountsView.js
    const dataSpec = {
        label: 'Companies',
        table: 'admin/companies',
        fields: {
            access: {
                label: 'Access',
                enabled: true,
            },
            account_id: {
                label: 'Account ID',
                enabled: true,
            },
            company_name: {
                label: 'Company name',
                enabled: true,
            },
            child_companies_count: {
                label: 'Instances',
                enabled: true,
                readOnly: true,
                disableSort: true,
            },
            processors_count: {
                label: 'Processors',
                enabled: true,
                readOnly: true,
                disableSort: true,
            },
            prompts_count: {
                label: 'Prompts',
                enabled: true,
                readOnly: true,
                disableSort: true,
            },
            company_products_count: {
                id: 'products',
                label: 'Products',
                enabled: true,
                readOnly: true,
                disableSort: true,
            },
            documents_count: {
                id: 'documents',
                label: 'Documents',
                enabled: true,
                readOnly: true,
                disableSort: true,
            },
            canonical_id: {
                id: 'canonical_id',
                label: 'Fintary company',
                type: FieldTypes.DYNAMIC_SELECT,
                table: 'companies?admin_mode=true?all=1',
                enabled: true,
                readOnly: (val) => {
                    return val.access === 'global';
                },
                formatter: (val, row) => {
                    var _a;
                    if ((typeof row === 'object' && (row === null || row === void 0 ? void 0 : row.access) === 'global') ||
                        val === 'N/A') {
                        return 'N/A';
                    }
                    return ((Array.isArray(row) &&
                        ((_a = row === null || row === void 0 ? void 0 : row.find((company) => company.id === val)) === null || _a === void 0 ? void 0 : _a.company_name)) ||
                        '');
                },
                optionFormatter: (option) => option.company_name,
                optionValuer: (option) => option.id,
                required: true,
            },
            created_at: {
                label: 'Created at',
                enabled: true,
                formatter: (s) => new Date(s).toLocaleString(),
                readOnly: true,
            },
        },
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            global: {
                id: 'global',
                label: 'Fintary',
                query: {
                    access: 'global',
                },
                addBtnLabel: 'Add fintary company',
                showAddBtn: true,
                addFields: {
                    account_id: {
                        label: 'Account ID',
                        enabled: true,
                        readOnly: true,
                        formatter: () => AccountIds.FINTARY_GLOBAL,
                        value: AccountIds.FINTARY_GLOBAL,
                    },
                    access: {
                        label: 'Access',
                        enabled: true,
                        readOnly: true,
                        formatter: () => 'global',
                        value: 'global',
                    },
                    state: {
                        label: 'State',
                        enabled: true,
                    },
                    company_name: {
                        label: 'Company name',
                        enabled: true,
                    },
                },
            },
            account: {
                id: 'account',
                label: 'Account',
                query: {
                    access: 'account',
                },
            },
            missing_mappings: {
                id: 'missing_mappings',
                label: 'Missing mappings',
                query: {
                    access: 'account',
                    canonical_id: null,
                },
            },
        },
    };
    const notUpdateFields = ['company_products', 'documents'];
    return (_jsx(EnhancedDataView
    // @ts-ignore
    , { 
        // @ts-ignore
        notUpdateFields: notUpdateFields, dataSpec: dataSpec, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false }));
};
export default CompanyView;
