export const IMG_TYPES = ['image/png', 'image/jpeg'];
export const PDF_TYPES = ['application/pdf'];
export const PDF_HTML_IMG_TYPES = [
  'application/pdf',
  'text/html',
  'image/png',
  'image/jpeg',
];
export const XLS_CSV_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];
export const Plain_TEXT_TYPES = ['text/plain'];
export const HTML_TYPES = ['text/html'];

export type PreviewType = 'excel' | 'pdf' | 'img';
export interface SpreadsheetModel {
  getSheets: () => string[];
  getJson: (sheet: string) => any[];
}

export interface SpreadsheetProps {
  spreadsheet?: SpreadsheetModel;
  setSpreadsheet?: React.Dispatch<
    React.SetStateAction<SpreadsheetModel | undefined>
  >;
}
