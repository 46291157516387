var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { LoadingContext } from 'contexts/LoadingContext';
import { removeLeadingTrailingChar, tryDecodeURIComponent, } from 'common/helpers';
import Spreadsheet from '@/services/Spreadsheet';
import FilePreview from '@/common/preview';
import { XLS_CSV_TYPES } from '@/common/preview/model';
import API from '@/services/API';
import usePreviewParams from '@/contexts/usePreviewParams';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import { DocumentPreviewKeys } from '@/types';
const FileDialogPreview = ({ fileId, showPreview, setShowPreview, isAdmin = false, }) => {
    const [previewFile, setPreviewFile] = useState();
    const [spreadsheet, setSpreadsheet] = useState();
    const { setLoadingConfig } = useContext(LoadingContext);
    const { previewType } = usePreviewParams();
    const { data: documentData, isLoading } = API.getBasicQuery('documents', `id=${fileId}`, !!fileId && !isAdmin);
    const { data: adminDocumentData, isLoading: adminDocumentLoading } = API.getBasicQuery('admin/documents', `id=${fileId}`, !!fileId && isAdmin);
    const { downloadFile } = useDownloadStorageFile();
    useEffect(() => {
        setLoadingConfig({
            loading: isLoading || adminDocumentLoading,
            message: 'Loading...',
        });
    }, [isLoading]);
    useEffect(() => {
        const readFile = () => __awaiter(void 0, void 0, void 0, function* () {
            const doc = isAdmin ? adminDocumentData === null || adminDocumentData === void 0 ? void 0 : adminDocumentData.data[0] : documentData === null || documentData === void 0 ? void 0 : documentData.data[0];
            if (isLoading || adminDocumentLoading || !doc)
                return;
            try {
                setLoadingConfig({
                    loading: true,
                });
                const file = yield downloadFile({
                    endpoint: 'documents',
                    endpoint_str_id: doc.str_id,
                    file_preview_type: previewType === DocumentPreviewKeys.OVERRIDE
                        ? 'override'
                        : 'original',
                });
                setPreviewFile(file);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoadingConfig({
                    loading: false,
                });
            }
        });
        readFile();
    }, [
        documentData,
        isLoading,
        adminDocumentData,
        adminDocumentLoading,
        isAdmin,
        setLoadingConfig,
        downloadFile,
        previewType,
    ]);
    useEffect(() => {
        // Excel, csv need to load the raw data
        const setExcelData = () => __awaiter(void 0, void 0, void 0, function* () {
            if (previewFile && XLS_CSV_TYPES.includes(previewFile === null || previewFile === void 0 ? void 0 : previewFile.type)) {
                const res = (yield Spreadsheet.loadSpreadsheet(previewFile));
                setSpreadsheet(res);
            }
        });
        setExcelData();
    }, [previewFile]);
    return (_jsxs(Dialog, { open: showPreview, onClose: () => {
            setShowPreview(false);
        }, fullScreen: true, sx: { p: 2 }, PaperProps: { sx: { borderRadius: 2 } }, children: [_jsx(DialogTitle, { sx: { pb: 1 }, children: _jsxs(Box, { className: "flex justify-between items-center", children: [_jsx(Box, { children: "Document preview" }), _jsx(Box, { className: "group cursor-pointer hover:text-blue-600", onClick: () => {
                                setShowPreview(false);
                            }, children: _jsx(CloseIcon, { className: "group-hover:rotate-180 transition-all origin-center" }) })] }) }), _jsx(DialogContent, { sx: { pb: 1 }, children: _jsx(FilePreview, { previewFile: previewFile, previewWidth: window.innerWidth * 0.8, spreadsheet: spreadsheet, setSpreadsheet: setSpreadsheet }) }), _jsxs(DialogActions, { children: [_jsx(Button, { disabled: !previewFile, onClick: () => {
                            saveAs(previewFile, removeLeadingTrailingChar(tryDecodeURIComponent(previewFile.name), '"'));
                        }, children: "Download" }), _jsx(Button, { onClick: () => {
                            setShowPreview(false);
                        }, variant: "contained", children: "Close" })] })] }));
};
export default FileDialogPreview;
