import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
const HTMLPreview = ({ file, previewWidth }) => {
    const [htmlSrc, setHtmlSrc] = useState(null);
    const iframeRef = useRef(null);
    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                var _a;
                const rawHtml = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result;
                const cleanHtml = DOMPurify.sanitize(rawHtml, {
                    FORBID_TAGS: [
                        'script',
                        'a',
                        'embed',
                        'iframe',
                        'comment',
                        'embed',
                        'link',
                        'listing',
                        'meta',
                        'noscript',
                        'object',
                        'plaintext',
                        'script',
                        'xmp',
                    ],
                    FORBID_ATTR: ['on*'],
                });
                setHtmlSrc(cleanHtml);
            };
            reader.readAsText(file);
        }
    }, [file]);
    return (_jsx("div", { style: {
            padding: '20px',
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
        }, children: htmlSrc ? (_jsx("iframe", { ref: iframeRef, srcDoc: htmlSrc, width: previewWidth || '100%', height: "100%", style: {
                border: 'none',
                boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
            }, sandbox: "allow-scripts allow-same-origin" })) : (_jsx("p", { children: "Loading preview..." })) }));
};
export default HTMLPreview;
