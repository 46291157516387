import { CreateCustomerFromPolicies } from 'common/customer/customer.types';
import { useMemo } from 'react';

export const useUpdateGroupIds = ({
  data,
  groupIds,
}: {
  data: CreateCustomerFromPolicies[];
  groupIds: { [key: string]: string };
}) => {
  const newData = useMemo(() => {
    return data.map((item) => {
      item.group_id = groupIds[item.clientId] || item.group_id;
      return { ...item };
    });
  }, [data, groupIds]);

  return { data: newData };
};
