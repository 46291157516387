import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ApiGetResponose } from 'common/types/companies';

import { API_PATHS } from './path';

export const useCompanies = ({ isAdmin }: { isAdmin?: boolean } = {}) => {
  const apiPath = isAdmin ? API_PATHS.adminCompanies : API_PATHS.companies;
  return useQuery<ApiGetResponose>({
    queryKey: [apiPath, isAdmin],
    queryFn: () =>
      axios.get(apiPath, { params: { limit: 10000 } }).then((res) => res.data),
  });
};
