export const documentAICode = `
/**
 * libs: {document: current file document data, tools: {key: function}} All function name are listed in the top
 */
(json, libs) => {
  const tableRows = json.table.reduce((acc, val) => {
    const filterData = val.filter((row) => row.length > 4);
    return acc.concat(filterData);
  }, []);

  console.log(tableRows)

  const fieldMap = {
    "Policy #": 'policy_id',
    "Insured Name": 'customer_name',
    "PREMIUM": 'premium_amount',
    "RATE": 'commission_rate',
    "COMM": 'commission_amount',
  }
  
  const resultList = [];
  const processDate = new Date().toLocaleDateString('en');

  const { tableHeaders, tableData } = libs.tools.pickData(fieldMap, tableRows);
  if (tableHeaders.length) {
      const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
      resultList.push(...res)
  }

  const _temp = resultList
  .map((item) => {
    item['carrier_name'] = 'Foresters';
    item['writing_carrier_name'] = 'Foresters';
    item['processing_date'] = processDate;
    return item;
  })
  console.log(_temp);
  const {keys, values} = libs.tools.convertMapToArray(_temp);

  return {
    fields: keys,
    data: values,
    version: 'documentAI',
  };
}

`;

export const spreadSheetCode = `
// TODO: Add additional utility functions here as needed

(data, libs) => {
  // ====== Section 1: Filter table data and extract surrounding fields ======
  data = JSON.parse(JSON.stringify(data));

  // TODO: Filter redundant rows and navigate some fields outside of the table here
  let filterData = data;

  if (!filterData.length) {
    console.log('No valid data found');
    return;
  }
  // ====== Section 2: Map and transform to structured data ======
  // TODO: Add a mapping here
  const fieldMap = {
    "Policy Number": 'policy_id',
    "Insured Name": 'customer_name',
    "Plan Code": 'product_name',

    "Agent Id": 'agent_id',
    "Agent Name": 'agent_name',

    "Type": 'transaction_type',

    "Effective Date": 'effective_date',

    "Premium": 'premium_amount',
    "Comp Rate": 'commission_rate',
    "Amount": 'commission_amount',
  }

  const resultList = [];
  const { tableHeaders, tableData } = libs.tools.pickData(fieldMap, filterData);
  if (tableHeaders.length) {
      const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
      resultList.push(...res)
  }

  // ====== Section 3: Clean table data and add common fields ======
  // TODO: Add additional fields here
  const processDate = new Date().toLocaleDateString('en');
  
  const _temp = resultList
  .map((item) => {
    // TODO: Add or tweak additional fields here
    item['carrier_name'] = 'Company name';
    item['writing_carrier_name'] = 'Company name';
    item['processing_date'] = processDate;
    return item;
  })
  console.log(_temp);
  
  const {keys, values} = libs.tools.convertMapToArray(_temp);
  return {
    fields: keys,
    data: values,
    version: 'SpreadSheet',
  };
}
`;

export const extractTableCode = `
// TODO: Add additional utility functions here as needed

(json, libs) => {
  // ====== Section 1: Filter table data and extract surrounding fields ======
  let resultObj = {};
  json.Tables.forEach((table, index) => {
      const dataArray = libs.tools.mapToArray(table.TableJson);
      const [header, ...r] = dataArray;

      // TODO: Filter rows by header length here
      if (header.length > 7){
        const _k = table.Page + '-' + index + '-' + header.length;
        let filterData = dataArray;
        // TODO: Filter redundant rows and navigate some fields outside of the table here
        resultObj[_k] = filterData;
      }
  });
  console.log(resultObj);

  // ====== Section 2: Map and transform to structured data ======
  // TODO: Add a mapping here
  const fieldMap = {
    "Policy Number": 'policy_id',
    "Insured Name": 'customer_name',
    "Plan Code": 'product_name',

    "Agent Id": 'agent_id',
    "Agent Name": 'agent_name',

    "Type": 'transaction_type',

    "Effective Date": 'effective_date',

    "Premium": 'premium_amount',
    "Comm Rate": 'commission_rate',
    "Amount": 'commission_amount',
  }

  const resultList = [];
  Object.entries(resultObj).forEach(([k, v]) => {
    // const [ tableHeaders, tableData ] = libs.tools.autoMapping(resultObj[k]);
    const { tableHeaders, tableData } = libs.tools.pickData(fieldMap, resultObj[k]);
    const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
    resultList.push(...res)
  })
  
  // ====== Section 3: Clean table data and add common fields ======
  // TODO: Add additional fields here
  const processDate = new Date().toLocaleDateString('en');
  const paymentDate = libs.tools.findAllDate(json)[0]
  
  const _temp = resultList
  .map((item) => {
    // TODO: Add or tweak additional fields here
    item['carrier_name'] = 'Company name';
    item['writing_carrier_name'] = 'Company name';
    item['payment_date'] = paymentDate;
    item['period_date'] = paymentDate;
    item['processing_date'] = processDate;
    return item;
  })
  console.log(_temp);
  
  const {keys, values} = libs.tools.convertMapToArray(_temp);
  return {
    fields: keys,
    data: values,
    version: 'extractTable',
  };
}
`;

export const nanonetsCode = `
  // TODO: Add additional utility functions here as needed

  (json, libs) => {
    // ====== Section 1: Filter table data and extract surrounding fields ======
    let resultObj = {};
    json.map((table, index) => {
      let dataArray = libs.tools.mapToArray(table.prediction);
 
      const [header, ...r] = dataArray;

      // TODO: Filter rows by header length here
      if (header.length > 7) {
        const _k = table.page + '-' + index + '-' + header.length;
        let filterData = dataArray;
        // TODO: Filter redundant rows and navigate some fields outside of the table here
        resultObj[_k] = filterData;
      }
    });
    console.log(resultObj);

    // ====== Section 2: Map and transform to structured data ======
    // TODO: Add a mapping here
    const fieldMap = {
      "Policy Number": 'policy_id',
      "Insured Name": 'customer_name',
      "Plan Code": 'product_name',

      "Agent Id": 'agent_id',
      "Agent Name": 'agent_name',

      "Type": 'transaction_type',

      "Effective Date": 'effective_date',

      "Premium": 'premium_amount',
      "Comp Rate": 'commission_rate',
      "Amount": 'commission_amount',
    };

    const resultList = [];
    Object.entries(resultObj).forEach(([k, v]) => {
      const { tableHeaders, tableData } = libs.tools.pickData(
        fieldMap,
        resultObj[k]
      );
      const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
      resultList.push(...res);
    });

    // ====== Section 3: Clean table data and add common fields ======
    // TODO: Add additional fields here
    const processDate = new Date().toLocaleDateString('en');

    const _temp = resultList.map((item) => {
      // TODO: Add or tweak additional fields here
      item['carrier_name'] = 'Company name';
      item['writing_carrier_name'] = 'Company name';
      item['processing_date'] = processDate;
      return item;
    });
    console.log(_temp);

    const { keys, values } = libs.tools.convertMapToArray(_temp);
    return {
      fields: keys,
      data: values,
      version: 'extractTable',
    };
  };
  `;

export const htmlExtractCode = `
// TODO: Add additional utility functions here as needed

(json, libs) => {
  // ====== Section 1: Filter table data and extract surrounding fields ======
  let resultObj = {};
  json.map((table, index) => {
      const dataArray = libs.tools.mapToArray(table.data);

      // TODO: Filter rows by header length here
      if (dataArray.length > 30){
        const [header, ...r] = dataArray;
        const _k = index + '-' + header.length;
        let filterData = dataArray;
        // TODO: Filter redundant rows and navigate some fields outside of the table here
        resultObj[_k] = filterData;
      }
  });
  console.log(resultObj);

  // ====== Section 2: Map and transform to structured data ======
  // TODO: Add a mapping here
  const fieldMap = {
    "Policy Number": 'policy_id',
    "Insured Name": 'customer_name',
    "Plan Code": 'product_name',

    "Agent Id": 'agent_id',
    "Agent Name": 'agent_name',

    "Type": 'transaction_type',

    "Effective Date": 'effective_date',

    "Premium": 'premium_amount',
    "Comm Rate": 'commission_rate',
    "Amount": 'commission_amount',
  }

  const resultList = [];
  Object.entries(resultObj).forEach(([k, v]) => {
    // const [ tableHeaders, tableData ] = libs.tools.autoMapping(resultObj[k]);
    const { tableHeaders, tableData } = libs.tools.pickData(fieldMap, resultObj[k]);
    const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
    resultList.push(...res)
  })
  
  // ====== Section 3: Clean table data and add common fields ======
  // TODO: Add additional fields here
  const processDate = new Date().toLocaleDateString('en');
  const paymentDate = libs.tools.findAllDate(json)[0]
  
  const _temp = resultList
  .map((item) => {
    // TODO: Add or tweak additional fields here
    item['carrier_name'] = 'Company name';
    item['writing_carrier_name'] = 'Company name';
    item['payment_date'] = paymentDate;
    item['period_date'] = paymentDate;
    item['processing_date'] = processDate;
    return item;
  })
  console.log(_temp);
  
  const {keys, values} = libs.tools.convertMapToArray(_temp);
  return {
    fields: keys,
    data: values,
    version: 'htmlExtract',
  };
}
`;
