import { CustomerType } from 'common/customer/customer.constants';
import {
  CreateCustomerFromPolicies,
  Customer,
} from 'common/customer/customer.types';

export const useLinkExistingCustomersByNames = ({
  data,
  existingCustomers,
}: {
  data: CreateCustomerFromPolicies[];
  existingCustomers: Customer[];
}) => {
  const dataWithExistingCustomers = data.map(
    (item): CreateCustomerFromPolicies => {
      if (item.type === CustomerType.group) {
        const existingCustomer = existingCustomers.find(
          (customer) => customer.company_name === item.company_name
        );
        if (existingCustomer) {
          item.customer_id = existingCustomer.id;
        }
      } else {
        const existingCustomer = existingCustomers.find(
          (customer) =>
            customer.first_name === item.first_name &&
            customer.last_name === item.last_name
        );
        if (existingCustomer) {
          item.customer_id = existingCustomer.id;
        }
      }

      return { ...item };
    }
  );
  return { data: dataWithExistingCustomers };
};
