var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, TextField, } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useUserInfo } from '@/hooks/useUserInfo';
import { useCompanies } from '@/api/companies';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const ActionDialog = ({ open, setOpen, rowData, documentProfiles }) => {
    var _a, _b, _c, _d;
    const { data: { data: companies } = { data: [] } } = useCompanies();
    const { data: companyList = [] } = API.getBasicQuery('companies/all');
    const { showSnackbar } = useSnackbar();
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({
        bank_total_amount: '',
        check_date: null,
        company_str_id: '',
        profile_str_id: '',
        deposit_date: null,
        notes: '',
        status: '',
        type: '',
    });
    const [error, setError] = useState(false);
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    const [search, setSearch] = useState('');
    const statusList = useMemo(() => [
        {
            id: 'new',
            label: 'New',
        },
        {
            id: 'processed',
            label: 'Processed',
        },
    ], []);
    const typeList = useMemo(() => [
        {
            id: 'statement',
            label: 'Statement',
        },
        {
            id: 'report',
            label: 'Report',
        },
    ], []);
    const documentProfileOptions = useMemo(() => {
        return (documentProfiles || [])
            .map((profile) => {
            const company = companyList.find((company) => company.str_id === profile.carrier_name);
            const carrierName = company ? company.company_name : '(Blank)';
            const profileName = profile.name ? profile.name : '(Blank)';
            const displayName = `${carrierName} - ${profileName}`;
            return {
                id: profile.str_id,
                label: displayName,
            };
        })
            .filter((option) => {
            if (search) {
                const searchLower = search.toLowerCase();
                return option.label.toLowerCase().includes(searchLower);
            }
            return true;
        });
    }, [documentProfiles, companyList, search]);
    const apiPutter = API.getMutation('documents', 'PUT');
    useEffect(() => {
        if (rowData) {
            const { bank_total_amount, check_date, company_str_id, profile_str_id, deposit_date, notes, status, type, } = rowData;
            setFormData({
                bank_total_amount,
                check_date,
                company_str_id,
                profile_str_id,
                deposit_date,
                notes,
                status,
                type,
            });
        }
    }, [rowData]);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setUploading(true);
        const { id, str_id } = rowData;
        const body = Object.assign(Object.assign({}, formData), { str_id,
            id });
        try {
            const result = yield apiPutter.mutateAsync(body);
            setUploading(false);
            if (result.error) {
                showSnackbar(result.error, 'error');
                return;
            }
            if (result) {
                setOpen(false, 'save');
            }
        }
        catch (error) {
            showSnackbar((error === null || error === void 0 ? void 0 : error.message) || error, 'error');
            setUploading(false);
        }
    });
    const onFormChange = (key, value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
    };
    return (_jsxs(Dialog, { open: open, maxWidth: "md", fullWidth: true, sx: { background: 'transparent', p: 1 }, onClose: () => setOpen(false), disableEscapeKeyDown: true, children: [_jsx(DialogTitle, { children: _jsx(Box, { className: "flex items-center", children: "Edit document" }) }), _jsx(IconButton, { className: "group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600", onClick: () => setOpen(false), children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                    p: 0,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                }, children: _jsxs(Box, { sx: { width: '100%', maxHeight: '700px', p: 2 }, children: [_jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(EnhancedSelect, { label: "Type", sx: { width: '100%' }, value: {
                                    id: formData.type || '',
                                    label: ((_a = typeList.find((type) => type.id === formData.type)) === null || _a === void 0 ? void 0 : _a.label) ||
                                        '',
                                }, options: typeList, onChange: (value) => {
                                    onFormChange('type', value.id);
                                } }) }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(EnhancedSelect, { label: "Company", sx: { width: '100%' }, value: {
                                    id: formData.company_str_id || '',
                                    label: ((_b = companies.find((company) => company.str_id === formData.company_str_id)) === null || _b === void 0 ? void 0 : _b.company_name) || '',
                                }, options: companies.map((company) => ({
                                    id: company.str_id,
                                    label: company.company_name,
                                })), onChange: (value) => {
                                    onFormChange('company_str_id', value.id);
                                } }) }), fintaryAdmin && (_jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(EnhancedSelect, { enableSearch: true, label: "Document profiles \uD83D\uDD12", sx: { width: '100%' }, listContainerSx: {
                                    width: 800,
                                }, value: {
                                    id: formData.profile_str_id || '',
                                    label: ((_c = documentProfileOptions.find((option) => option.id === formData.profile_str_id)) === null || _c === void 0 ? void 0 : _c.label) || '',
                                }, options: documentProfileOptions, onChange: (value) => {
                                    onFormChange('profile_str_id', value.id);
                                }, onSearch: setSearch, searchKeyword: search }) })), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(EnhancedSelect, { label: "Status", sx: { width: '100%' }, value: {
                                    id: formData.status || '',
                                    label: ((_d = statusList.find((status) => status.id === formData.status)) === null || _d === void 0 ? void 0 : _d.label) || '',
                                }, options: statusList, onChange: (value) => {
                                    onFormChange('status', value.id);
                                } }) }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(TextField, { label: "Bank total", variant: "outlined", value: formData.bank_total_amount, error: error, helperText: error ? 'Invalid currency format' : '', onChange: (e) => {
                                    let val = e.target.value;
                                    const scientificNotationRegex = /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?$/;
                                    if (scientificNotationRegex.test(val)) {
                                        const numericValue = parseFloat(val);
                                        if (!isNaN(numericValue)) {
                                            val = numericValue.toLocaleString('fullwide', {
                                                useGrouping: false,
                                            });
                                        }
                                    }
                                    const isValidCurrency = validator.isCurrency(val, {
                                        allow_negatives: true,
                                        thousands_separator: ',',
                                        decimal_separator: '.',
                                        allow_decimal: true,
                                        digits_after_decimal: [1, 2],
                                    });
                                    if (!isValidCurrency ||
                                        parseFloat(val) > 1e12 ||
                                        parseFloat(val) < -1e12) {
                                        setError(true);
                                    }
                                    else {
                                        setError(false);
                                    }
                                    onFormChange('bank_total_amount', e.target.value);
                                } }) }), _jsx(Box, { children: _jsx(BasicDatePicker, { label: "Check date", value: formData.check_date, setValue: (e) => onFormChange('check_date', e), sx: { width: 250 } }) }), _jsx(Box, { children: _jsx(BasicDatePicker, { label: "Deposit date", value: formData.deposit_date, setValue: (e) => onFormChange('deposit_date', e), sx: { my: 1, width: 250 } }) }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(TextField, { label: "Notes", variant: "outlined", multiline: true, rows: 4, value: formData.notes, onChange: (e) => {
                                    onFormChange('notes', e.target.value);
                                } }) })] }) }), _jsxs(DialogActions, { sx: { pt: 0, pb: 2, px: 2 }, children: [_jsx(Button, { onClick: () => setOpen(false), children: "Cancel" }), _jsx(LoadingButton, { onClick: onSubmit, loading: uploading, variant: "contained", sx: { width: '100px' }, disabled: error, children: "Save" })] })] }));
};
export default ActionDialog;
