import { useMemo } from 'react';
import { Policy } from 'common/policies/policies.types';
import { splitCustomerName } from 'common/utils';

import { useCustomersByNames } from '../apis';

export const useExistingCustomers = (data: Policy[]) => {
  const names = useMemo(() => {
    const last_names = new Set<string>();
    const first_names = new Set<string>();
    const company_names = new Set<string>();

    data.map((item) => {
      const { first_name, last_name } = splitCustomerName(item.customer_name);
      if (first_name) first_names.add(first_name);
      if (last_name) last_names.add(last_name);
      const name = `${first_name || ''} ${last_name || ''}`.trim();
      if (name) company_names.add(name);
    });
    return {
      last_names: [...last_names],
      first_names: [...first_names],
      company_names: [...company_names],
    };
  }, [data]);

  const { data: existingCustomers } = useCustomersByNames(names);

  return { existingCustomers };
};
