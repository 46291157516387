var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, } from '@mui/material';
import { isValidJsonString } from 'common/helpers';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import fillDown from '@/common/tools/fillDown';
import BasicTable from '@/components/molecules/BasicTable';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { ExpandableTextField } from '@/components/molecules/ExpandableTextField';
const splitByParens = (str) => {
    const match = str.match(/(.*?)\s*\(([^)]+)\)/);
    if (match) {
        return [match[1].trim(), match[2]];
    }
    else {
        return [str.trim(), null];
    }
};
const parseRange = (range) => {
    const result = {
        min: null,
        max: null,
    };
    if (!range)
        return result;
    const cleanedRange = range
        .replace(/\s+/g, '')
        .replace(/(\d+)<(?!\d)/g, (match, p1) => {
        const number = parseInt(p1, 10);
        return (number - 1).toString();
    });
    const rangePattern = /^(\d+)-(\d+)$/;
    const minPattern = /^>(\d+)$/;
    const maxPattern = /^<(\d+)$/;
    const minPlusPattern = /^(\d+)\+$/;
    const sameSamePattern = /^(\d+)$/;
    if (rangePattern.test(cleanedRange)) {
        const [, min, max] = cleanedRange.match(rangePattern);
        result.min = Number(min);
        result.max = Number(max);
    }
    else if (minPattern.test(cleanedRange)) {
        const [, min] = cleanedRange.match(minPattern);
        result.min = Number(min);
    }
    else if (maxPattern.test(cleanedRange)) {
        const [, max] = cleanedRange.match(maxPattern);
        result.max = Number(max);
    }
    else if (minPlusPattern.test(cleanedRange)) {
        const [, min] = cleanedRange.match(minPlusPattern);
        result.min = Number(min);
    }
    else if (sameSamePattern.test(cleanedRange)) {
        const [, min] = cleanedRange.match(sameSamePattern);
        result.min = Number(min);
        result.max = Number(min);
    }
    else {
        throw new Error(`Invalid range format: "${range}"`);
    }
    return result;
};
const dropColumnsByStrings = (data, colsToDrop, opts = { substringMatch: true }) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const columnIndexes = headerRow
        .map((header, index) => colsToDrop.some((substring) => opts.substringMatch
        ? header.toLowerCase().includes(substring.toLowerCase())
        : header.toLowerCase() === substring.toLowerCase())
        ? index
        : -1)
        .filter((index) => index !== -1);
    if (columnIndexes.length === 0) {
        console.warn(`No headers include any of the substrings "${colsToDrop.join(', ')}".`);
    }
    return data.map((row) => row.filter((_, index) => !columnIndexes.includes(index)));
};
const combineHeaders = (data) => {
    if (data.length < 2) {
        throw new Error('The input array must have at least two rows.');
    }
    const firstRow = data[0];
    const secondRow = data[1];
    const combinedHeader = [];
    let currentPrefix = '';
    const levels = [];
    for (let i = 0; i < secondRow.length; i++) {
        if (firstRow[i]) {
            currentPrefix = firstRow[i];
            levels.push(currentPrefix);
        }
        combinedHeader.push(currentPrefix && secondRow[i] !== 'TG rate effective date'
            ? `${currentPrefix}::${secondRow[i]}`
            : secondRow[i]);
    }
    return {
        levels,
        data: [combinedHeader, ...data.slice(2)],
    };
};
const duplicateColumn = (data, header, newHeader, transformer = (data) => data) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const columnIndex = headerRow.indexOf(header);
    if (columnIndex === -1) {
        console.warn(`Header "${header}" not found.`);
        // Throw new Error(`Header "${header}" not found.`);
    }
    const newHeaderRow = [...headerRow, newHeader];
    const newData = data.map((row, rowIndex) => {
        if (rowIndex === 0) {
            return newHeaderRow;
        }
        const newCol = transformer(row[columnIndex]);
        const newRow = [...row, newCol];
        return newRow;
    });
    return newData;
};
const padToHeaderLength = (data) => {
    if (data.length === 0)
        throw new Error('The input array must not be empty.');
    const headerRow = data[0];
    const headerLength = headerRow.length;
    const paddedData = data.map((row) => {
        const paddedRow = [...row];
        while (paddedRow.length < headerLength) {
            paddedRow.push(null);
        }
        return paddedRow;
    });
    return paddedData;
};
const splitByPrefix = (data, sharedFields, levels, fields) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const result = {};
    levels.forEach((level) => {
        const newHeaderRow = [...sharedFields, ...fields];
        const newData = data.map((row, i) => {
            if (i === 0)
                return newHeaderRow;
            const newRow = [];
            newHeaderRow.forEach((header) => {
                const index = sharedFields.includes(header)
                    ? headerRow.indexOf(header)
                    : headerRow.indexOf(`${level}::${header}`);
                // NewRow.push(index !== -1 ? row[index] : null);
                if (index !== -1)
                    newRow.push(row[index]);
            });
            return newRow;
        });
        result[level] = newData;
    });
    return result;
};
const splitByLevelDupeShared = (data, sharedFields, levelField) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const result = {};
    const levels = headerRow.filter((header) => !sharedFields.includes(header));
    levels.forEach((level) => {
        const newHeaderRow = [...sharedFields, levelField];
        const newData = data.map((row, i) => {
            if (i === 0)
                return newHeaderRow;
            const newRow = [];
            newHeaderRow.forEach((header) => {
                const index = sharedFields.includes(header)
                    ? headerRow.indexOf(header)
                    : headerRow.indexOf(level);
                newRow.push(index !== -1 ? row[index] : null);
            });
            return newRow;
        });
        result[level] = newData;
    });
    return result;
};
const renameHeaders = (data, generateNewHeader) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const newHeaderRow = headerRow.map((header) => generateNewHeader(header));
    return [newHeaderRow, ...data.slice(1)];
};
const addColWithFill = (data, newHeader, fillValue) => {
    const headerRow = data[0];
    const newHeaderRow = [...headerRow, newHeader];
    const newData = data.map((row, rowIndex) => {
        if (rowIndex === 0) {
            return newHeaderRow;
        }
        const newRow = [...row, fillValue];
        return newRow;
    });
    return newData;
};
const groupByColumn = (colName, gridsByLevels) => {
    const groupedData = {};
    Object.entries(gridsByLevels).forEach(([level, rows]) => {
        const colIndex = rows[0].indexOf(colName);
        const headers = rows[0].filter((_, i) => i !== colIndex);
        rows.forEach((row, i) => {
            if (i === 0)
                return;
            const colVal = row[colIndex];
            if (colIndex === -1)
                throw Error('Column value cannot be empty');
            if (!groupedData[colVal])
                groupedData[colVal] = {};
            if (!groupedData[colVal][level])
                groupedData[colVal][level] = [headers];
            groupedData[colVal][level].push(row.filter((_, i) => i !== colIndex));
        });
    });
    return groupedData;
};
const parseTGGrid = (jsonRes, metadata) => {
    let { levels, data: gridData } = combineHeaders(jsonRes);
    levels = levels.filter((level) => ['TG', 'AT', 'A0', 'A1', 'A2', 'A3', 'S', 'A', 'B'].includes(level));
    gridData = gridData.filter((row) => row.some((cell) => cell !== null));
    gridData = padToHeaderLength(gridData);
    gridData = dropColumnsByStrings(gridData, [
        'Bonus',
        'Broker',
        'Contract code',
        'LIBRA',
        'TG::Pass Thru',
        'TG::Gross',
    ]);
    gridData = renameHeaders(gridData, (header) => {
        return header
            .replace('Gross Comm', 'total_rate')
            .replace('Comm from TG', 'house_rate')
            .replace('Comm from Carrier', 'carrier_rate')
            .replace('TG rate effective date', 'effective_date')
            .replace('Carrier Grid Effective Date', 'effective_date')
            .replace(/TG Stmt\s+\(3\)/, 'total_rate')
            .replace('Carrier', 'grid_name')
            .replace('Product Type', 'product_type')
            .replace('Product', 'product_name')
            .replace('Commission Type', '_compensation_type')
            .replace('Age Group', 'age_range');
    });
    levels.forEach((level) => {
        gridData = duplicateColumn(gridData, 'effective_date', `${level}::effective_date`);
    });
    gridData = duplicateColumn(gridData, 'TG::total_rate', 'TG::carrier_rate');
    gridData = duplicateColumn(gridData, 'TG::total_rate', 'TG::house_rate', (v) => null);
    gridData = duplicateColumn(gridData, '_compensation_type', 'policy_year_start', (range) => { var _a, _b; return parseRange((_b = (_a = splitByParens(range)) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.replace(/^Yr\s*/g, '')).min; });
    gridData = duplicateColumn(gridData, '_compensation_type', 'policy_year_end', (range) => { var _a, _b; return parseRange((_b = (_a = splitByParens(range)) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.replace(/^Yr\s*/g, '')).max; });
    gridData = duplicateColumn(gridData, '_compensation_type', 'compensation_type', (range) => { var _a; return (_a = splitByParens(range)) === null || _a === void 0 ? void 0 : _a[0]; });
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_start', (range) => parseRange(range).min);
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_end', (range) => parseRange(range).max);
    const gridsByLevels = splitByPrefix(gridData, [
        'grid_name',
        'product_type',
        'product_name',
        'issue_age_start',
        'issue_age_end',
        'policy_year_start',
        'policy_year_end',
        'compensation_type',
    ], levels, ['effective_date', 'carrier_rate', 'house_rate', 'total_rate']);
    const gridsByGrids = groupByColumn('grid_name', gridsByLevels);
    return gridsByGrids;
};
const hasRequiredRates = (data) => {
    const rateIndexMap = data[0].reduce((acc, header, index) => {
        if (['carrier_rate', 'house_rate', 'total_rate'].includes(header)) {
            acc[header] = index;
        }
        return acc;
    }, {});
    const rateIndicies = Object.values(rateIndexMap);
    return data.filter((row, index) => index === 0 || rateIndicies.some((i) => row[i] !== null));
};
const parseBAGrid = (jsonRes, metadata) => {
    let gridData = jsonRes;
    gridData = fillDown(gridData, [
        'AI Product Name',
        'AI Carrier ID',
        'AI Product ID',
        'Types',
        'State',
    ]);
    gridData = dropColumnsByStrings(gridData, ['AI Carrier ID', 'AI Product ID', 'State', 'Product'], { substringMatch: false });
    gridData = renameHeaders(gridData, (header) => {
        return header
            .replace(/^Types$/i, 'product_type')
            .replace(/^AI Product Name$/i, 'product_name')
            .replace(/^Compensation type$/i, 'compensation_type')
            .replace(/^Sub Name$/i, 'age_range')
            .replace(/^Age range$/i, 'age_range')
            .replace(/^Policy years?$/i, 'policy_years')
            .replace(/^Premium range$/i, 'premium_range');
    });
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_start', (range) => (range ? parseRange(range.replace(/Ages?\s*/g, '')).min : null));
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_end', (range) => range ? parseRange(range.replace(/Ages?\s*/g, '')).max : null);
    gridData = duplicateColumn(gridData, 'policy_years', 'policy_year_start', (range) => range ? parseRange(range.replace(/(Policy )?Years?\s*/g, '')).min : null);
    gridData = duplicateColumn(gridData, 'policy_years', 'policy_year_end', (range) => range ? parseRange(range.replace(/(Policy )?Years?\s*/g, '')).max : null);
    gridData = duplicateColumn(gridData, 'premium_range', 'premium_min', (range) => (range ? parseRange(range).min : null));
    gridData = duplicateColumn(gridData, 'premium_range', 'premium_max', (range) => (range ? parseRange(range).max : null));
    gridData = dropColumnsByStrings(gridData, [
        'age_range',
        'policy_years',
        'premium_range',
    ]);
    gridData = addColWithFill(gridData, 'grid_name', metadata.gridName);
    if (!gridData[0].includes('compensation_type')) {
        gridData = addColWithFill(gridData, 'compensation_type', 'Override');
    }
    gridData = splitByLevelDupeShared(gridData, [
        'grid_name',
        'product_type',
        'product_name',
        'issue_age_start',
        'issue_age_end',
        'policy_year_start',
        'policy_year_end',
        'premium_min',
        'premium_max',
        'compensation_type',
    ], 'carrier_rate');
    gridData = Object.fromEntries(Object.entries(gridData).map(([k, v]) => [k, hasRequiredRates(v)]));
    return gridData;
};
const parsers = {
    BrokersAlliance: parseBAGrid,
    // Fintary: () => {},
    TransGlobal: parseTGGrid,
};
const DataBulkAdd = ({ params, handleUpdateParams, onCancel, btnLabel = 'Bulk add', }) => {
    var _a, _b;
    const mutation = API.getMutation('comp-grids/import-rates', 'POST');
    const [csv, setCsv] = useState('');
    const [grids, setGrids] = useState({});
    const { showSnackbar } = useSnackbar();
    const [selectedPreviewGrid, setSelectedPreviewGrid] = useState('');
    const [selectedPreviewGridLevel, setSelectedPreviewGridLevel] = useState('');
    const [selectedGridToUpdate, setSelectedGridToUpdate] = useState(null);
    const [createGridStructure, setCreateGridStructure] = useState(false);
    const [validationResults, setValidationResults] = useState([]);
    const [errors, setErrors] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams({});
    const parser = (_a = searchParams.get('parser')) !== null && _a !== void 0 ? _a : '';
    const setParser = (val) => {
        setSearchParams((prev) => {
            if (val)
                prev.set('parser', val);
            else
                prev.delete('parser');
            return prev;
        });
    };
    const { data: existingCompGrids } = API.getBasicQuery('comp-grids');
    useEffect(() => {
        var _a;
        try {
            setGrids({});
            setErrors([]);
            setValidationResults({});
            const csvSheet = XLSX.read(csv, {
                type: 'string',
                raw: true,
            });
            const jsonRes = XLSX.utils.sheet_to_json(csvSheet.Sheets[csvSheet.SheetNames[0]], { raw: true, header: 1 });
            if (jsonRes.length <= 1)
                return;
            const gridsByGrids = parser
                ? (_a = parsers === null || parsers === void 0 ? void 0 : parsers[parser]) === null || _a === void 0 ? void 0 : _a.call(parsers, jsonRes, { gridName: selectedGridToUpdate === null || selectedGridToUpdate === void 0 ? void 0 : selectedGridToUpdate.name })
                : {};
            setGrids(gridsByGrids);
        }
        catch (e) {
            setErrors([e === null || e === void 0 ? void 0 : e.message]);
            console.error(e);
        }
    }, [csv, selectedGridToUpdate, parser]);
    return (_jsxs(Box, { sx: { width: '100%', px: 1 }, children: [_jsx(Typography, { children: "Import grid in csv/tsv format" }), _jsx(Typography, { variant: "body2", children: "Comp grids must already exist and all entities must be unique. Structure can be created if selected (products, criteria, levels). Dates must be later than existing date ranges. Existing open ended date ranges will be ended the day before new dates specified in import." }), _jsxs(Box, { sx: { mt: 1 }, children: [_jsx(EnhancedSelect, { options: ['', ...Object.keys(parsers)], value: parser, onChange: setParser, enableSearch: true, sortLabel: true, label: "Grid template *" }), parser === 'BrokersAlliance' && (_jsx(EnhancedSelect, { options: [{ name: '' }, ...(existingCompGrids !== null && existingCompGrids !== void 0 ? existingCompGrids : [])], label: "Comp grid *", value: selectedGridToUpdate, onChange: setSelectedGridToUpdate, labelKey: "name", valueKey: "name" }))] }), _jsx(ExpandableTextField, { label: "Comp grid (csv/tsv)", defaultValue: csv, onChange: setCsv, sx: { mt: 1.5, width: '100%' }, editableSx: { height: 'unset', maxHeight: '60vh', overflow: 'scroll' } }), params && (_jsx(TextField, { label: "Params", value: params, onChange: handleUpdateParams, sx: { width: '100%', mt: 1 }, fullWidth: true, error: !isValidJsonString(params), helperText: !isValidJsonString(params) && 'Invalid JSON string' })), _jsxs(Box, { sx: {
                    mt: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }, children: [_jsx(Box, { children: _jsx(Button, { variant: "outlined", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                try {
                                    const res = yield mutation.mutateAsync({
                                        data: grids,
                                        opts: { validateOnly: true, parser },
                                    });
                                    setValidationResults(res.data);
                                }
                                catch (e) {
                                    console.error(e);
                                    showSnackbar(e.message, 'error');
                                }
                            }), disabled: mutation.isPending, children: "Check structure" }) }), _jsxs(Box, { sx: { display: 'flex', justifyContent: 'flex-end' }, children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: createGridStructure, onChange: (e) => setCreateGridStructure(e.target.checked) }), label: "Create grid structure" }), _jsx(Button, { sx: { mr: 1 }, onClick: onCancel, children: "Cancel" }), _jsx(Button, { variant: "contained", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    try {
                                        yield mutation.mutateAsync({
                                            data: grids,
                                            opts: { createGridStructure, parser },
                                        });
                                        showSnackbar('Grids imported', 'success');
                                    }
                                    catch (e) {
                                        console.error(e);
                                        showSnackbar(e.message, 'error');
                                    }
                                }), disabled: (params && !isValidJsonString(params)) || mutation.isPending, children: btnLabel })] })] }), _jsx(Box, { children: errors.map((error) => (_jsx(Typography, { color: "error", children: error }, error))) }), Object.keys(validationResults).length > 0 && (_jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { children: "Comp grid structure validation" }), Object.entries(validationResults).map(([gridName, gridValidationResults]) => (_jsxs(Box, { children: [_jsxs(Typography, { variant: "body2", sx: { ml: 2 }, children: [gridValidationResults.grid.missing.length
                                        ? '❌ '
                                        : Object.values(gridValidationResults).every((v) => v.missing.length === 0)
                                            ? '✅ '
                                            : '⚠️ ', gridName] }), gridValidationResults.grid.missing.length > 0 && (_jsx(Typography, { variant: "body2", sx: { ml: 4 }, children: "Missing comp grid - needs to be manually created" })), gridValidationResults.grid.missing.length === 0
                                ? Object.entries(gridValidationResults).map(([entity, entityResults]) => (_jsxs(Typography, { variant: "body2", sx: { ml: 4 }, children: [entity, ":", ' ', entityResults.missing.length === 0
                                            ? `All match (${entityResults.existing.length})`
                                            : `${entityResults.existing.length} match, ${entityResults.missing.length} missing.`, entityResults.missing.length
                                            ? ` (${entityResults.missing.join(', ')})`
                                            : ''] }, entity)))
                                : ''] }, gridName)))] })), Object.keys(grids).length > 0 && (_jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { children: "Summary of parsed input" }), _jsxs(Typography, { variant: "body2", sx: { ml: 2 }, children: ["Comp grids (", Object.keys(grids).length, ")"] }), Object.entries(grids).map(([gridName, gridLevels]) => {
                        const levels = Object.keys(gridLevels !== null && gridLevels !== void 0 ? gridLevels : {});
                        return (_jsxs(Typography, { variant: "body2", sx: { ml: 4 }, children: [gridName, ": Levels (", levels.length, "):", ' ', Object.entries(gridLevels !== null && gridLevels !== void 0 ? gridLevels : {})
                                    .map(([level, rows]) => `${level} (${rows.length - 1})`)
                                    .join(', ')] }, gridName));
                    }), _jsx(EnhancedSelect, { label: "Grids", options: ['', ...Object.keys(grids)], value: selectedPreviewGrid, onChange: setSelectedPreviewGrid, sx: { mt: 1 }, enableSearch: true }), ' ', _jsx(EnhancedSelect, { label: "Grid levels", options: ['', ...Object.keys((_b = grids[selectedPreviewGrid]) !== null && _b !== void 0 ? _b : {})], value: selectedPreviewGridLevel, onChange: setSelectedPreviewGridLevel, sx: { mt: 1 }, enableSearch: true }), selectedPreviewGrid && selectedPreviewGridLevel && (_jsx(Box, { sx: { mt: 2 }, children: _jsx(BasicTable, { headers: grids[selectedPreviewGrid][selectedPreviewGridLevel][0], rows: grids[selectedPreviewGrid][selectedPreviewGridLevel].slice(1), nowrap: true }) }))] }))] }));
};
export default DataBulkAdd;
