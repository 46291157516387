import { Policy } from 'common/policies/policies.types';

import { DedupeValue } from '../constants';
import { DedupeList } from '../types';

const dedupeByName = (data: Policy[]) => {
  const dedupedList: DedupeList = [];
  const dedupedData: { [key: string]: Policy[] } = {};
  data.forEach((item) => {
    const key = item.customer_name;

    if (!dedupedData[key]) {
      dedupedData[key] = [];
    }
    dedupedData[key].push(item);
  });

  const list = Object.values(dedupedData);
  list.forEach((item) => {
    const firstItem = item[0];
    const policy_str_ids = item.map((i) => i.str_id).flat();
    const newItem: DedupeList[number] = {
      ...firstItem,
      dedupe_group_ids: [...new Set(item.map((i) => i.group_id || '').flat())],
      policy_str_ids,
      clientId: policy_str_ids.join('-'),
      policies_ids: item.map((i) => i.policy_id).flat(),
    };
    dedupedList.push(newItem);
  });
  return dedupedList;
};

const dedupeByGroupId = (data: Policy[]) => {
  const dedupedList: DedupeList = [];
  const dedupedData: { [key: string]: Policy[] } = {};

  data.forEach((item) => {
    if (item.group_id) {
      const key = item.group_id;
      if (!dedupedData[key]) {
        dedupedData[key] = [];
      }
      dedupedData[key].push(item);
    } else {
      dedupedData[item.str_id] = [item];
    }
  });

  const list = Object.values(dedupedData);

  list.forEach((item) => {
    const firstItem = item[0];
    const policy_str_ids = item.map((i) => i.str_id).flat();
    const newItem: DedupeList[number] = {
      ...firstItem,
      dedupe_customers_names: [
        ...new Set(item.map((i) => i.customer_name).flat()),
      ],
      policy_str_ids,
      clientId: policy_str_ids.join('-'),
      policies_ids: item.map((i) => i.policy_id).flat(),
    };
    dedupedList.push(newItem);
  });

  return dedupedList;
};

const dedupeByInternalId = (data: Policy[]) => {
  const dedupedList: DedupeList = [];
  const dedupedData: { [key: string]: Policy[] } = {};

  data.forEach((item) => {
    if (item.internal_id) {
      const key = item.internal_id;
      if (!dedupedData[key]) {
        dedupedData[key] = [];
      }
      dedupedData[key].push(item);
    } else {
      dedupedData[item.str_id] = [item];
    }
  });

  const list = Object.values(dedupedData);

  list.forEach((item) => {
    const firstItem = item[0];
    const policy_str_ids = item.map((i) => i.str_id).flat();
    const newItem: DedupeList[number] = {
      ...firstItem,
      dedupe_customers_names: [
        ...new Set(item.map((i) => i.customer_name).flat()),
      ],
      dedupe_group_ids: [...new Set(item.map((i) => i.group_id || '').flat())],
      policy_str_ids,
      clientId: policy_str_ids.join('-'),
      policies_ids: item.map((i) => i.policy_id).flat(),
    };
    dedupedList.push(newItem);
  });

  return dedupedList;
};

export const useDeduplication = ({
  data,
  dedupeBy,
}: {
  data: Policy[];
  dedupeBy: DedupeValue;
}) => {
  switch (dedupeBy) {
    case DedupeValue.Name:
      return { dedupedList: dedupeByName(data) };
    case DedupeValue.GroupId:
      return { dedupedList: dedupeByGroupId(data) };
    default:
      return { dedupedList: dedupeByInternalId(data) };
  }
};
