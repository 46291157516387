var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, TextField, Typography, } from '@mui/material';
import { Close as CloseIcon, DeleteOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Formatter from 'common/Formatter';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CommonFormatter from 'common/Formatter';
import API from '@/services/API';
import LoadingCircle from '../atoms/LoadingCircle';
import MultiSelect from './MultiSelect';
import DataView from '@/components/DataView';
import useSnackbar from '@/contexts/useSnackbar';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
dayjs.extend(utc);
dayjs.extend(timezone);
const DateRangeManager = ({ row, field, setter, type, }) => {
    var _a;
    const [isLoading, setIsLoading] = useState(true);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newStartDate, setNewStartDate] = useState(null);
    const [newEndDate, setNewEndDate] = useState(null);
    const [newNotes, setNewNotes] = useState('');
    const [newName, setNewName] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [dialogWidth, setDialogWidth] = useState('xs');
    const handleTabChange = (event, newValue) => {
        if (newValue === 0)
            setDialogWidth('xs');
        else
            setDialogWidth('md');
        setActiveTab(newValue);
    };
    const { showSnackbar } = useSnackbar();
    const { data: dateRangesData, isFetched: isFetchedDateRangesData, refetch: refetchDateRanges, } = API.getBasicQuery('date-ranges', `type=${type}`);
    const dateRangesPoster = API.getMutation('date-ranges', 'POST');
    const dateRangesDeleter = API.getMutation('date-ranges', 'DELETE');
    useEffect(() => {
        if (isFetchedDateRangesData)
            setIsLoading(false);
    }, [isFetchedDateRangesData]);
    useEffect(() => {
        if (dateRangesData) {
            const selectedDateRangeObjects = selectedDateRange
                .map((id) => dateRangesData.find((dateRange) => dateRange.id === id))
                .filter(Boolean);
            const uniqueDateRanges = row.date_ranges
                ? row.date_ranges.filter((dateRange) => selectedDateRange.includes(dateRange.id))
                : [];
            const updatedDateRanges = [
                ...uniqueDateRanges,
                ...selectedDateRangeObjects,
            ].filter((dateRange, index, self) => index === self.findIndex((dr) => dr.id === dateRange.id));
            setter(Object.assign(Object.assign({}, row), { date_ranges: updatedDateRanges }));
        }
    }, [selectedDateRange, dateRangesData]);
    const dataDesc = {
        label: '',
        table: 'date-ranges',
        editable: true,
        copyable: false,
        bulkAdd: false,
        fields: [
            {
                id: 'id',
                label: 'ID',
                visible: false,
                readOnly: true,
            },
            { id: 'name', label: 'Name', editable: true },
            {
                id: 'start_date',
                label: 'Start date',
                type: 'date',
                formatter: (value) => (value ? Formatter.date(value) : ''),
            },
            {
                id: 'end_date',
                label: 'End date',
                type: 'date',
                formatter: (value) => (value ? Formatter.date(value) : ''),
            },
            {
                id: 'notes',
                label: 'Notes',
                editable: true,
            },
        ],
        actions: [
            {
                label: 'Delete',
                type: 'iconButton',
                icon: _jsx(DeleteOutline, {}),
                onClick: (row) => __awaiter(void 0, void 0, void 0, function* () {
                    const confirmDelete = window.confirm('Are you sure you want to delete this date range?');
                    if (confirmDelete) {
                        dateRangesDeleter.mutate({ id: row.id }, {
                            onSuccess: (res) => {
                                if (res.statusText && res.statusText === 'error') {
                                    showSnackbar('An error occurred when deleting date range', 'error');
                                }
                                else {
                                    showSnackbar('Date range deleted', 'success');
                                    refetchDateRanges();
                                }
                            },
                        });
                    }
                }),
            },
        ],
    };
    const addNewDateRange = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const params = {
            start_date: newStartDate,
            end_date: newEndDate,
            type: type,
            notes: newNotes,
            name: newName,
        };
        dateRangesPoster.mutate(params, {
            onSuccess: (res) => {
                if (res.statusText && res.statusText === 'error') {
                    showSnackbar(` An error occurred when creating new date range. ${(res === null || res === void 0 ? void 0 : res.error) || ''}`, 'error');
                }
                else {
                    showSnackbar('Date range created', 'success');
                    setNewEndDate(null);
                    setNewStartDate(null);
                    setNewNotes('');
                    setNewName('');
                    setOpenAddDialog(false);
                    refetchDateRanges();
                }
                setIsLoading(false);
            },
        });
    };
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: isLoading ? (_jsx(Box, { sx: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }, children: _jsx(LoadingCircle, {}) })) : (_jsxs(Box, { sx: {
                        mb: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                    }, children: [_jsxs(Box, { sx: { display: 'flex', mt: 0.5 }, children: [_jsx(MultiSelect, { label: "Add", values: dateRangesData || [], selectedValues: selectedDateRange, setSelectedValues: setSelectedDateRange, formatter: (dateRange) => dateRange.name
                                        ? `${dateRange.name}: ${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
                                        : `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`, valuer: (dateRange) => dateRange.id, sx: { width: 145 } }), _jsx(Button, { variant: "outlined", onClick: () => setOpenAddDialog(true), sx: { ml: 1 }, children: "Manage date ranges" })] }), _jsx(Box, { sx: { my: 0.5, display: 'flex', flexWrap: 'wrap' }, children: (_a = row.date_ranges) === null || _a === void 0 ? void 0 : _a.map((selectedDateRange) => (_jsx(Box, { sx: {
                                    mr: 0.5,
                                    mb: 0.5,
                                    pl: 1,
                                    borderStyle: 'solid',
                                    borderColor: 'silver',
                                    borderWidth: 1,
                                    borderRadius: 6,
                                    display: 'inline-block',
                                    backgroundColor: '#2196f30a',
                                }, children: _jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: selectedDateRange.name
                                                ? `${selectedDateRange.name}: ${CommonFormatter.dateRange(selectedDateRange.start_date, selectedDateRange.end_date)}`
                                                : `${CommonFormatter.dateRange(selectedDateRange.start_date, selectedDateRange.end_date)}` }), _jsx(IconButton, { onClick: () => {
                                                setter(Object.assign(Object.assign({}, row), { date_ranges: row.date_ranges.filter((item) => item.id !== selectedDateRange.id) }));
                                            }, children: _jsx(CloseIcon, {}) })] }) }, `${selectedDateRange.id}-selected`))) })] })) }, `${field.id}-box`), _jsxs(Dialog, { open: openAddDialog, onClose: () => setOpenAddDialog(false), fullWidth: true, maxWidth: dialogWidth, children: [_jsx(DialogTitle, { children: "Manage date ranges" }), _jsxs(DialogContent, { children: [_jsxs(Tabs, { value: activeTab, onChange: handleTabChange, children: [_jsx(Tab, { label: "Create" }), _jsx(Tab, { label: "View" })] }), activeTab === 0 && (_jsxs(_Fragment, { children: [_jsx(Box, { sx: {
                                            mt: 2,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }, children: _jsx(BasicDateRangePicker, { range: {
                                                startDate: newStartDate ? dayjs.utc(newStartDate) : null,
                                                startDateLabel: 'Start date',
                                                endDate: newEndDate ? dayjs.utc(newEndDate) : null,
                                                endDateLabel: 'End date',
                                            }, onChange: ({ startDate, endDate }) => {
                                                setNewStartDate(startDate
                                                    ? dayjs.isDayjs(startDate)
                                                        ? startDate.toDate()
                                                        : new Date(startDate)
                                                    : null);
                                                setNewEndDate(endDate
                                                    ? dayjs.isDayjs(endDate)
                                                        ? endDate.toDate()
                                                        : new Date(endDate)
                                                    : null);
                                            }, mr: 0.5 }) }), _jsx(Box, { sx: {
                                            mt: 1,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }, children: _jsx(TextField, { label: "Name", value: newName, onChange: (e) => setNewName(e.target.value), sx: { width: '100%' }, size: "small" }) }), _jsx(Box, { sx: {
                                            mt: 1,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }, children: _jsx(TextField, { label: "Notes", value: newNotes, onChange: (e) => setNewNotes(e.target.value), sx: { width: '100%' }, size: "small" }) }), _jsxs(Box, { sx: {
                                            mt: 2,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'right',
                                            width: '100%',
                                        }, children: [_jsx(Button, { onClick: () => {
                                                    setNewStartDate(null);
                                                    setNewEndDate(null);
                                                    setNewNotes('');
                                                    setNewName('');
                                                    setOpenAddDialog(false);
                                                }, children: "Cancel" }), _jsx(Button, { variant: "contained", onClick: (e) => addNewDateRange(e), sx: { ml: 1 }, children: "Create" })] })] })), activeTab === 1 && (_jsxs(Box, { sx: {
                                    maxHeight: 600,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }, children: [_jsx(Box, { sx: {
                                            display: 'flex',
                                            mb: 2,
                                            overflowY: 'scroll',
                                        }, children: _jsx(DataView, { dataDesc: dataDesc, hideExport: true, embed: true, viewOnly: true, headingOffset: 392 }) }), _jsx(Box, { sx: {
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                        }, children: _jsx(Button, { onClick: () => {
                                                setNewStartDate(null);
                                                setNewEndDate(null);
                                                setNewNotes('');
                                                setNewName('');
                                                setOpenAddDialog(false);
                                            }, children: "Close" }) })] }))] })] })] }));
};
export default DateRangeManager;
