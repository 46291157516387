import {
  AccountCircleOutlined,
  AccountTreeOutlined,
  AdminPanelSettingsOutlined,
  BookmarksOutlined,
  BusinessOutlined,
  ConstructionOutlined,
  CurrencyExchangeOutlined,
  EventNoteOutlined,
  FilePresent,
  FilterAltOutlined,
  FolderCopyOutlined,
  FormatAlignJustifyOutlined,
  FormatListBulletedOutlined,
  GroupsOutlined,
  History,
  InsightsOutlined,
  LeaderboardOutlined,
  ListAlt,
  ManageSearch,
  Merge,
  MultilineChartOutlined,
  NewReleasesOutlined,
  PaymentOutlined,
  PaymentsOutlined,
  PeopleOutlined,
  ReadMore,
  ReceiptLongOutlined,
  ReceiptOutlined,
  RequestQuoteOutlined,
  Route,
  SaveAltOutlined,
  SaveOutlined,
  SellOutlined,
  SettingsOutlined,
  StorageOutlined,
  StyleOutlined,
  SupportAgentOutlined,
  TableChartOutlined,
  ViewCarouselOutlined,
  ViewColumnOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import Rule from '@mui/icons-material/Rule';
import { AccountIds } from 'common/constants';
import React from 'react';

import { ROUTES } from '@/constants/routes';
import { auth } from '@/firebase';
import UILabels from '@/services/UILabels';
import { Roles } from '@/types';

interface DashboardName {
  name: string;
  label: string;
}

interface PageSettings {
  show_page?: boolean | undefined;
  menu_label?: string;
}

interface AccountSettings {
  pages_settings: {
    reconciliation?: PageSettings;
    commissions?: PageSettings;
    policies?: PageSettings;
    comp_grids_schedules?: PageSettings;
    carriers_schedules?: PageSettings;
    compensation_profile_sets?: PageSettings;
    compensation_profiles?: PageSettings;
    incentives_schedules?: PageSettings;
    insights?: PageSettings;
    businessInsights?: PageSettings;
    policiesInsights?: PageSettings;
    agentsInsights?: PageSettings;
    agentGroupInsights?: PageSettings;
    views?: PageSettings;
    summaries?: PageSettings;
    reports?: PageSettings;
    companies?: PageSettings;
    products?: PageSettings;
    options?: PageSettings;
    agents?: PageSettings;
    agents_groups?: PageSettings;
    agents_production?: PageSettings;
    customers?: PageSettings;
    documents?: PageSettings;
  };
  dashboardNames?: DashboardName[];
}

export const getRouteConfig = (
  mode: string | null | undefined,
  selectedAccount: Account | null | undefined,
  accountSettings: AccountSettings
) => {
  const labels = new UILabels(mode);

  const renderCustomDashboard = (dashboardNames) => {
    if (!dashboardNames) return [];
    return dashboardNames
      ?.filter((x) => x.name != null)
      .map((dashboardName) => ({
        label: dashboardName.label,
        icon: React.createElement(GroupsOutlined),
        target: `/insights/${dashboardName.name}`,
      }));
  };

  const enableCompGridManagement =
    selectedAccount?.accountId === AccountIds.TRANSGLOBAL;
  return [
    [
      ...(accountSettings?.pages_settings?.reconciliation?.show_page !== false
        ? [
            {
              label:
                accountSettings?.pages_settings?.reconciliation?.menu_label ??
                labels.getLabel('drawer', 'reconciliation'),
              icon: React.createElement(Rule),
              target: '/reconciliation',
              tooltip: '🅶 + 🅾',
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.commissions?.show_page !== false
        ? [
            {
              label:
                accountSettings?.pages_settings?.commissions?.menu_label ??
                labels.getLabel('drawer', 'cash'),
              icon: React.createElement(RequestQuoteOutlined),
              tooltip: '🅶 + 🅲',
              target: '/commissions',
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.policies?.show_page !== false
        ? [
            {
              label:
                accountSettings?.pages_settings?.policies?.menu_label ??
                labels.getLabel('drawer', 'transactions'),
              icon: React.createElement(ReceiptOutlined),
              target: '/policies',
              tooltip: '🅶 + 🅿',
            },
          ]
        : []),
      ...(() => {
        const schedulesSubItems = [
          ...(accountSettings?.pages_settings?.comp_grids_schedules
            ?.show_page !== false
            ? [
                {
                  label:
                    accountSettings?.pages_settings?.comp_grids_schedules
                      ?.menu_label ?? 'Comp grids',
                  icon: React.createElement(TableChartOutlined),
                  target: '/schedules/comp-grids/viewer',
                },
              ]
            : []),
          ...(accountSettings?.pages_settings?.compensation_profiles
            ?.show_page !== false
            ? [
                {
                  label:
                    accountSettings?.pages_settings?.compensation_profiles
                      ?.menu_label ?? 'Comp profiles',
                  icon: React.createElement(PaymentOutlined),
                  target: '/schedules/comp-profiles',
                },
              ]
            : []),
          ...(accountSettings?.pages_settings?.compensation_profile_sets
            ?.show_page !== false
            ? [
                {
                  label:
                    accountSettings?.pages_settings?.compensation_profile_sets
                      ?.menu_label ?? 'Comp profile sets',
                  icon: React.createElement(PaymentsOutlined),
                  target: '/schedules/comp-profile-sets',
                },
              ]
            : []),
          ...(accountSettings?.pages_settings?.carriers_schedules?.show_page !==
          false
            ? [
                {
                  label:
                    accountSettings?.pages_settings?.carriers_schedules
                      ?.menu_label ?? 'Carriers',
                  icon: React.createElement(BusinessOutlined),
                  target: '/schedules/carriers',
                },
              ]
            : []),
          ...(accountSettings?.pages_settings?.incentives_schedules
            ?.show_page !== false
            ? [
                {
                  label:
                    accountSettings?.pages_settings?.incentives_schedules
                      ?.menu_label ?? 'Incentives',
                  icon: React.createElement(LeaderboardOutlined),
                  target: '/schedules/incentive-tiers',
                },
              ]
            : []),
        ];
        return schedulesSubItems.length > 0
          ? [
              {
                label: 'Schedules',
                icon: React.createElement(EventNoteOutlined),
                target: schedulesSubItems,
              },
            ]
          : [];
      })(),
      ...(accountSettings?.pages_settings?.insights?.show_page !== false
        ? [
            {
              label:
                accountSettings?.pages_settings?.insights?.menu_label ??
                labels.getLabel('drawer', 'dashboard'),
              icon: React.createElement(InsightsOutlined),
              target: [
                ...(accountSettings?.pages_settings?.insights?.show_page ===
                  true ||
                accountSettings?.pages_settings?.insights?.show_page ===
                  undefined
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.insights
                            ?.menu_label ?? 'Dashboard',
                        icon: React.createElement(StorageOutlined),
                        target: '/insights',
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.businessInsights
                  ?.show_page !== false
                  ? renderCustomDashboard(accountSettings?.dashboardNames)
                  : []),
                ...(accountSettings?.pages_settings?.policiesInsights
                  ?.show_page !== false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.policiesInsights
                            ?.menu_label ?? 'Policies (Life clients)',
                        icon: React.createElement(LeaderboardOutlined),
                        target: '/insights/policies-insights',
                        access: [Roles.FINTARY_ADMIN],
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.agentsInsights
                  ?.show_page !== false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.agentsInsights
                            ?.menu_label ?? 'Agents',
                        icon: React.createElement(SupportAgentOutlined),
                        target: '/insights/agents-insights',
                        access: [Roles.FINTARY_ADMIN],
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.agentGroupInsights
                  ?.show_page !== false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.agentGroupInsights
                            ?.menu_label ?? 'Agent Group',
                        icon: React.createElement(GroupsOutlined),
                        target: '/insights/agent-group-insights',
                        access: [Roles.FINTARY_ADMIN],
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.views?.show_page !== false
        ? [
            {
              label:
                accountSettings?.pages_settings?.views?.menu_label ?? 'Views',
              icon: React.createElement(BookmarksOutlined),
              target: '/views',
              tooltip: '🅶 + 🆅',
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.summaries?.show_page !== false ||
      accountSettings?.pages_settings?.reports?.show_page !== false
        ? [
            {
              label: 'Reports',
              icon: React.createElement(SaveOutlined),
              target: [
                ...(accountSettings?.pages_settings?.summaries?.show_page !==
                false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.summaries
                            ?.menu_label ?? 'Summaries',
                        icon: React.createElement(ViewCarouselOutlined),
                        target: '/reports/summary',
                        access: [Roles.ACCOUNT_ADMIN],
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.reports?.show_page !==
                false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.reports
                            ?.menu_label ?? 'Reports',
                        icon: React.createElement(StorageOutlined),
                        target: '/reports',
                      },
                    ]
                  : []),
              ],
              tooltip: '🅶 + 🆁',
              access: [Roles.ACCOUNT_ADMIN, Roles.PRODUCER],
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.companies?.show_page !== false ||
      accountSettings?.pages_settings?.products?.show_page !== false ||
      accountSettings?.pages_settings?.options?.show_page !== false
        ? [
            {
              label: 'Companies',
              icon: React.createElement(BusinessOutlined),
              target: [
                ...(accountSettings?.pages_settings?.companies?.show_page !==
                false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.companies
                            ?.menu_label ?? 'Companies',
                        icon: React.createElement(StorageOutlined),
                        target: '/companies',
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.products?.show_page !==
                false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.products
                            ?.menu_label ?? 'Products',
                        icon: React.createElement(SellOutlined),
                        target: '/companies/products',
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.options?.show_page !==
                false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.options
                            ?.menu_label ?? 'Options',
                        icon: React.createElement(StyleOutlined),
                        target: '/companies/products/options',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.agents?.show_page !== false ||
      accountSettings?.pages_settings?.agents_groups?.show_page !== false ||
      accountSettings?.pages_settings?.agents_production?.show_page !== false
        ? [
            {
              label: 'Agents',
              icon: React.createElement(SupportAgentOutlined),
              target: [
                ...(accountSettings?.pages_settings?.agents?.show_page !== false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.agents?.menu_label ??
                          'Agents',
                        icon: React.createElement(StorageOutlined),
                        target: '/agents/list',
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.agents_groups
                  ?.show_page !== false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.agents_groups
                            ?.menu_label ?? 'Groups',
                        icon: React.createElement(GroupsOutlined),
                        target: '/agents/groups',
                      },
                    ]
                  : []),
                ...(accountSettings?.pages_settings?.agents_production
                  ?.show_page !== false
                  ? [
                      {
                        label:
                          accountSettings?.pages_settings?.agents_production
                            ?.menu_label ?? 'Production',
                        icon: React.createElement(MultilineChartOutlined),
                        target: '/agents/production',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.customers?.show_page === true
        ? [
            {
              label:
                accountSettings?.pages_settings?.customers?.menu_label ??
                ROUTES.customers.name,
              icon: React.createElement(PeopleOutlined),
              target: ROUTES.customers.url,
            },
          ]
        : []),
      ...(accountSettings?.pages_settings?.documents?.show_page !== false
        ? [
            {
              label:
                accountSettings?.pages_settings?.documents?.menu_label ??
                labels.getLabel('drawer', 'documents'),
              icon: React.createElement(FilePresent),
              target: [
                {
                  label: labels.getLabel('drawer', 'documents'),
                  icon: React.createElement(StorageOutlined),
                  target: '/documents',
                },
                {
                  label: labels.getLabel('drawer', 'documents_groups'),
                  icon: React.createElement(FolderCopyOutlined),
                  target: '/documents/groups',
                  access: [Roles.FINTARY_ADMIN],
                },
              ],
              tooltip: '🅶 + 🅳',
            },
          ]
        : []),
      {
        label: labels.getLabel('drawer', 'settings'),
        icon: React.createElement(SettingsOutlined),
        target: '/settings',
        tooltip: '🅶 + 🆂',
        access: [Roles.ACCOUNT_ADMIN, Roles.PRODUCER, Roles.DATA_SPECIALIST],
      },
    ],
    [
      {
        label: 'Admin',
        icon: React.createElement(AdminPanelSettingsOutlined),
        target: [
          {
            label: 'Accounts',
            icon: React.createElement(AccountCircleOutlined),
            target: '/admin/accounts',
            tooltip: '🅶 + 🅰',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Users',
            icon: React.createElement(PeopleOutlined),
            target: '/admin/users',
            tooltip: '🅶 + 🆄',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Activity log',
            icon: React.createElement(ManageSearch),
            target: '/admin/activities',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Companies',
            icon: React.createElement(BusinessOutlined),
            target: '/admin/companies',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Documents',
            icon: React.createElement(FilePresent),
            target: '/admin/documents?qc=new',
            access: [Roles.FINTARY_ADMIN],
          },
          // {
          //   label: 'Processors',
          //   icon: <DeveloperBoard />,
          //   target: '/admin/processors',
          // },
          {
            label: 'Fields',
            icon: React.createElement(ListAlt),
            target: '/admin/fields',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Tools',
            icon: React.createElement(ConstructionOutlined),
            target: '/admin/tools',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Calculations',
            icon: React.createElement(ListAlt),
            target: '/admin/calculations',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Metrics',
            icon: React.createElement(InsightsOutlined),
            target: '/admin/metrics',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Releases',
            icon: React.createElement(NewReleasesOutlined),
            target: '/releases',
            access: [Roles.FINTARY_ADMIN],
          },
        ],
        access: [Roles.FINTARY_ADMIN],
      },
      {
        label: 'Comp grids',
        icon: React.createElement(TableChartOutlined),
        target: [
          {
            label: 'Viewer',
            icon: React.createElement(TableChartOutlined),
            target: '/schedules/comp-grids/viewer',
            access: enableCompGridManagement
              ? [Roles.ACCOUNT_ADMIN]
              : [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Comp grids',
            icon: React.createElement(ViewColumnOutlined),
            target: '/schedules/comp-grids',
            access: enableCompGridManagement
              ? [Roles.ACCOUNT_ADMIN]
              : [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Products',
            icon: React.createElement(SellOutlined),
            target: '/schedules/comp-grids/products',
            access: enableCompGridManagement
              ? [Roles.ACCOUNT_ADMIN]
              : [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Levels',
            icon: React.createElement(LeaderboardOutlined),
            target: '/schedules/comp-grids/levels',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Criteria',
            icon: React.createElement(FilterAltOutlined),
            target: '/schedules/comp-grids/criteria',
            access: enableCompGridManagement
              ? [Roles.ACCOUNT_ADMIN]
              : [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Rates',
            icon: React.createElement(TableChartOutlined),
            target: '/schedules/comp-grids/rates',
            access: [Roles.FINTARY_ADMIN],
          },
        ],
        access: enableCompGridManagement
          ? [Roles.ACCOUNT_ADMIN]
          : [Roles.FINTARY_ADMIN],
      },
      {
        label: 'Accounting',
        icon: React.createElement(CurrencyExchangeOutlined),
        target: [
          {
            label: 'Transactions',
            icon: React.createElement(FormatListBulletedOutlined),
            target: '/accounting/transactions',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Transaction details',
            icon: React.createElement(FormatAlignJustifyOutlined),
            target: '/accounting/transaction-details',
            access: [Roles.FINTARY_ADMIN],
          },
        ],
        access: [Roles.FINTARY_ADMIN],
      },
      {
        label: 'Documents',
        icon: React.createElement(ReceiptLongOutlined),
        target: [
          {
            label: labels.getLabel('drawer', 'documents'),
            icon: React.createElement(FilePresent),
            target: '/documents',
            access: ['admin', 'demo'],
          },
          {
            label: 'Extractions',
            icon: React.createElement(ReadMore),
            target: '/extractions',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: labels.getLabel('drawer', 'mappings'),
            icon: React.createElement(Route),
            target: '/mappings',
            access: [Roles.FINTARY_ADMIN],
          },
          {
            label: 'Imports',
            icon: React.createElement(SaveAltOutlined),
            target: '/imports',
            access: [Roles.FINTARY_ADMIN],
          },
        ],
        access: [Roles.FINTARY_ADMIN],
      },
      {
        label: 'Reconciliation',
        icon: React.createElement(Rule),
        target: [
          {
            label: 'Reconcilers',
            icon: React.createElement(Merge),
            target: '/reconciliation/reconcilers',
            access: ['admin', 'demo'],
          },
          {
            label: 'Flows',
            icon: React.createElement(AccountTreeOutlined),
            target: '/reconciliation/flows',
            access: ['admin', 'demo'],
          },
          {
            label: 'History',
            icon: React.createElement(History),
            target: '/reconciliation/history',
            access: [Roles.FINTARY_ADMIN],
          },
        ],
        access: [Roles.FINTARY_ADMIN],
      },
    ].filter((e) => e?.label),
    [
      auth?.currentUser?.email &&
      !['hello+bernardorobles@fintary.com'].includes(auth?.currentUser?.email)
        ? {
            label: 'Previews',
            icon: React.createElement(VisibilityOutlined),
            target: [
              {
                label: 'Reconciler',
                icon: React.createElement(Rule),
                target: '/reconciler',
                access: ['admin', 'demo'],
              },
              // {
              //   label: labels.getLabel('drawer', 'banking'),
              //   icon: <ReceiptLong />,
              //   target: '/transactions',
              //   access: ['admin', 'demo'],
              //   disabled: true,
              // },
            ],
            access: [Roles.FINTARY_ADMIN],
          }
        : {},
    ].filter((e) => e?.label),
  ];
};
