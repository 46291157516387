import { useEffect } from 'react';
import API from '@/services/API';
const useSyncedFields = (table) => {
    const { data, abort } = API.getBasicQuery('data_processing/sync/synced-fields');
    const { data: workerSyncedFields, abort: abortWorkerSyncedFields } = API.getBasicQuery('data_processing/sync/worker-synced-fields');
    useEffect(() => {
        return () => abort();
    }, []);
    const syncedFields = data === null || data === void 0 ? void 0 : data[table];
    return {
        syncedFields,
        workerSyncedFields,
        isSyncedField: (data, key, config) => {
            var _a, _b, _c;
            const fields = (_b = (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data.sync_worker]) === null || _a === void 0 ? void 0 : _a[table]) !== null && _b !== void 0 ? _b : syncedFields;
            return (data.sync_id &&
                (fields === null || fields === void 0 ? void 0 : fields.includes(key)) &&
                (!(config === null || config === void 0 ? void 0 : config.overrideFields) || !((_c = config === null || config === void 0 ? void 0 : config.overrideFields) === null || _c === void 0 ? void 0 : _c.includes(key))));
        },
    };
};
export const useSyncedFieldsNew = () => {
    const { data, abort } = API.getBasicQuery('data_processing/sync/worker-synced-fields');
    useEffect(() => {
        return () => abort();
    }, []);
    return {
        workerSyncedFields: data || {},
        isSyncedField: (data, syncedFields, key, config) => {
            var _a;
            // RiskTag syncs documents data back to BenefitPoint, and saves the entity_id in the sync_id field without sync worker
            return (data.sync_id &&
                data.sync_worker &&
                (syncedFields === null || syncedFields === void 0 ? void 0 : syncedFields.includes(key)) &&
                (!(config === null || config === void 0 ? void 0 : config.overrideFields) ||
                    !((_a = config === null || config === void 0 ? void 0 : config.overrideFields) === null || _a === void 0 ? void 0 : _a.includes(key))));
        },
    };
};
export default useSyncedFields;
