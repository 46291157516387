import { CustomerType } from 'common/customer/customer.constants';
import { CreateCustomerFromPolicies } from 'common/customer/customer.types';
import { useMemo, useState } from 'react';
import { splitCustomerName } from 'common/utils';

import { DedupeList } from '../types';

export const useMapTypeAndSplitName = ({
  data,
  customerNames,
}: {
  data: DedupeList;
  customerNames: Record<string, string>;
}) => {
  const [customersType, setCustomersType] = useState<
    Record<string, CustomerType>
  >({});

  const newData = useMemo(() => {
    return data.map((item): CreateCustomerFromPolicies => {
      const type =
        customersType[item.clientId] ||
        (item.group_id ? CustomerType.group : CustomerType.individual);

      const { first_name, last_name } = splitCustomerName(
        customerNames[item.clientId] || item.customer_name
      );
      const names =
        type === CustomerType.group
          ? { company_name: `${first_name} ${last_name}` }
          : { first_name, last_name };

      return {
        ...names,
        policy_str_ids: item.policy_str_ids,
        dedupe_group_ids: item.dedupe_group_ids,
        type,
        group_id: item.group_id,
        clientId: item.clientId,
        policies_ids: item.policies_ids,
        dedupe_customers_names: item.dedupe_customers_names,
        internal_id: item.internal_id,
      };
    });
  }, [customerNames, customersType, data]);

  return { data: newData, setCustomersType, customersType };
};
