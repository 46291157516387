export var CompGridRateFields;
(function (CompGridRateFields) {
    CompGridRateFields["ALL"] = "all";
    CompGridRateFields["CARRIER_RATE"] = "carrier_rate";
    CompGridRateFields["HOUSE_RATE"] = "house_rate";
    CompGridRateFields["TOTAL_RATE"] = "total_rate";
})(CompGridRateFields || (CompGridRateFields = {}));
export var ContactPayableStatuses;
(function (ContactPayableStatuses) {
    ContactPayableStatuses["PAYABLE"] = "payable";
    ContactPayableStatuses["NON_PAYABLE"] = "non_payable";
})(ContactPayableStatuses || (ContactPayableStatuses = {}));
export var ReportReviewStatuses;
(function (ReportReviewStatuses) {
    ReportReviewStatuses["None"] = "";
    ReportReviewStatuses["Draft"] = "draft";
    ReportReviewStatuses["Approved"] = "approved";
    ReportReviewStatuses["Rejected"] = "rejected";
    ReportReviewStatuses["InReview"] = "in_review";
    ReportReviewStatuses["RequestUpdate"] = "request_update";
    ReportReviewStatuses["NonPayable"] = "non_payable";
})(ReportReviewStatuses || (ReportReviewStatuses = {}));
export var ReportReviewStatusesLabels;
(function (ReportReviewStatusesLabels) {
    ReportReviewStatusesLabels["None"] = "";
    ReportReviewStatusesLabels["Draft"] = "Draft";
    ReportReviewStatusesLabels["Approved"] = "Approved";
    ReportReviewStatusesLabels["Rejected"] = "Rejected";
    ReportReviewStatusesLabels["InReview"] = "In review";
    ReportReviewStatusesLabels["RequestUpdate"] = "Request update";
    ReportReviewStatusesLabels["NonPayable"] = "Non-payable";
})(ReportReviewStatusesLabels || (ReportReviewStatusesLabels = {}));
export var CompReportDateFilter;
(function (CompReportDateFilter) {
    CompReportDateFilter["ProcessingDate"] = "processing_date";
    CompReportDateFilter["PaymentDate"] = "payment_date";
})(CompReportDateFilter || (CompReportDateFilter = {}));
export var CompReportPrefixConfig;
(function (CompReportPrefixConfig) {
    CompReportPrefixConfig["Default"] = "default_prefix";
    CompReportPrefixConfig["Custom"] = "custom_prefix";
})(CompReportPrefixConfig || (CompReportPrefixConfig = {}));
export var ProductTypes;
(function (ProductTypes) {
    ProductTypes["LIFE"] = "Life";
    ProductTypes["ANNUITY"] = "Annuity";
})(ProductTypes || (ProductTypes = {}));
export var CompensationTypes;
(function (CompensationTypes) {
    // COMMISSION = 'Commission',
    // OVERRIDE = 'Override',
    // EXCESS = 'Excess',
    // RENEWAL_COMMISSION = 'Renewal Commission',
    // TRAIL = 'Trail',
    // Use TG definitions until we have a config for this
    CompensationTypes["COMMISSION"] = "FYC";
    CompensationTypes["OVERRIDE"] = "FYC";
    CompensationTypes["EXCESS"] = "Excess";
    CompensationTypes["RENEWAL_COMMISSION"] = "Renew";
    CompensationTypes["TRAIL"] = "Trail";
})(CompensationTypes || (CompensationTypes = {}));
export var CompReportViewTypes;
(function (CompReportViewTypes) {
    CompReportViewTypes["PRODUCER_VIEW"] = "prod-view";
    CompReportViewTypes["ADMIN_VIEW"] = "admin-view";
})(CompReportViewTypes || (CompReportViewTypes = {}));
export var FiltersOperators;
(function (FiltersOperators) {
    // TODO: Add directional operators (e.g. within one year before | within one year after)
    FiltersOperators["EQ"] = "eq";
    FiltersOperators["NEQ"] = "neq";
    FiltersOperators["GT"] = "gt";
    FiltersOperators["LT"] = "lt";
    FiltersOperators["GTE"] = "gte";
    FiltersOperators["LTE"] = "lte";
    FiltersOperators["CONTAINS"] = "contains";
    FiltersOperators["NCONTAINS"] = "ncontains";
    FiltersOperators["CONTAINEDIN"] = "containedin";
    FiltersOperators["NCONTAINEDIN"] = "ncontainedin";
    FiltersOperators["STARTSWITH"] = "startswith";
    FiltersOperators["ENDSWITH"] = "endswith";
    FiltersOperators["BEFORE"] = "before";
    FiltersOperators["BEFORE_EQUALS"] = "beforeEquals";
    FiltersOperators["AFTER"] = "after";
    FiltersOperators["AFTER_EQUALS"] = "afterEquals";
    FiltersOperators["IS_EMPTY"] = "isEmpty";
    FiltersOperators["WITHIN_ONE_YEAR"] = "withinOneYear";
    FiltersOperators["AT_LEAST_ONE_YEAR"] = "atLeastOneYear";
    FiltersOperators["CUSTOM"] = "custom";
})(FiltersOperators || (FiltersOperators = {}));
export var DataProcessingAction;
(function (DataProcessingAction) {
    DataProcessingAction["SET_FIELD_VALUE"] = "set_field_value";
})(DataProcessingAction || (DataProcessingAction = {}));
export var AgentCommissionsStatusesLabels;
(function (AgentCommissionsStatusesLabels) {
    AgentCommissionsStatusesLabels["None"] = "(Blank)";
    AgentCommissionsStatusesLabels["Draft"] = "Draft";
    AgentCommissionsStatusesLabels["InReview"] = "In review";
    AgentCommissionsStatusesLabels["Manual"] = "Manual";
    AgentCommissionsStatusesLabels["Approved"] = "Approved";
    AgentCommissionsStatusesLabels["Paid"] = "Paid";
    AgentCommissionsStatusesLabels["NonPayable"] = "Non-payable";
})(AgentCommissionsStatusesLabels || (AgentCommissionsStatusesLabels = {}));
export var DataEntities;
(function (DataEntities) {
    DataEntities["COMMISSIONS"] = "Commissions";
    DataEntities["POLICIES"] = "Policies";
})(DataEntities || (DataEntities = {}));
export var ContactStatuses;
(function (ContactStatuses) {
    ContactStatuses["INACTIVE"] = "Inactive";
    // TODO: Add more statuses
})(ContactStatuses || (ContactStatuses = {}));
export var AccessTypes;
(function (AccessTypes) {
    AccessTypes["GLOBAL"] = "global";
    AccessTypes["ACCOUNT"] = "account";
})(AccessTypes || (AccessTypes = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "asc";
    SortOrder["DESC"] = "desc";
})(SortOrder || (SortOrder = {}));
export var ProcessorReviewStatuses;
(function (ProcessorReviewStatuses) {
    ProcessorReviewStatuses["None"] = "";
    ProcessorReviewStatuses["Draft"] = "draft";
    ProcessorReviewStatuses["Approved"] = "approved";
    ProcessorReviewStatuses["InReview"] = "in_review";
    ProcessorReviewStatuses["NeedsUpdate"] = "needs_update";
})(ProcessorReviewStatuses || (ProcessorReviewStatuses = {}));
export const ProcessorReviewStatusesLabels = {
    [ProcessorReviewStatuses.None]: '',
    [ProcessorReviewStatuses.Draft]: 'Draft',
    [ProcessorReviewStatuses.Approved]: 'Approved',
    [ProcessorReviewStatuses.InReview]: 'In review',
    [ProcessorReviewStatuses.NeedsUpdate]: 'Needs update',
};
export var ProcessorStatuses;
(function (ProcessorStatuses) {
    ProcessorStatuses["NEW"] = "new";
    ProcessorStatuses["PROCESSED"] = "processed";
})(ProcessorStatuses || (ProcessorStatuses = {}));
export var ProcessorSelectorStatuses;
(function (ProcessorSelectorStatuses) {
    ProcessorSelectorStatuses["SUCCESS"] = "success";
    ProcessorSelectorStatuses["PARTIAL"] = "partial";
    ProcessorSelectorStatuses["ERROR"] = "error";
})(ProcessorSelectorStatuses || (ProcessorSelectorStatuses = {}));
