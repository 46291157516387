import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
const BasicTable = ({ headers, rows, format = 'array', formatters, defaultShow = undefined, nowrap = false, }) => {
    const [expanded, setExpanded] = useState(false);
    const rowsToShow = rows === null || rows === void 0 ? void 0 : rows.slice(0, expanded ? undefined : defaultShow);
    useEffect(() => {
        if (defaultShow === undefined) {
            setExpanded(true);
        }
    }, [defaultShow]);
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: Array.isArray(headers) &&
                            headers.map((header) => (_jsx(TableCell, { children: header }, header))) }) }), _jsxs(TableBody, { children: [format === 'array'
                            ? rowsToShow === null || rowsToShow === void 0 ? void 0 : rowsToShow.map((row, i) => (_jsx(TableRow, { children: Array.isArray(row) &&
                                    (row === null || row === void 0 ? void 0 : row.map((cell, j) => j === 0 ? (_jsx(TableCell, { component: "th", scope: "row", style: { whiteSpace: nowrap ? 'nowrap' : 'normal' }, children: typeof (formatters === null || formatters === void 0 ? void 0 : formatters[j]) === 'function'
                                            ? formatters[j](cell)
                                            : cell }, j)) : (_jsx(TableCell, { style: { whiteSpace: nowrap ? 'nowrap' : 'normal' }, children: typeof (formatters === null || formatters === void 0 ? void 0 : formatters[j]) === 'function'
                                            ? formatters[j](cell)
                                            : cell }, j)))) }, `row-${i}`)))
                            : rowsToShow === null || rowsToShow === void 0 ? void 0 : rowsToShow.map((row, i) => {
                                var _a, _b;
                                return (_jsx(TableRow, { children: headers === null || headers === void 0 ? void 0 : headers.map((col, j) => j === 0 ? (_jsx(TableCell, { component: "th", scope: "row", style: { whiteSpace: nowrap ? 'nowrap' : 'normal' }, children: row[col] }, col)) : (_jsx(TableCell, { children: row[col] }, col))) }, (_b = (_a = row.name) !== null && _a !== void 0 ? _a : row.id) !== null && _b !== void 0 ? _b : `row-${i}`));
                            }), defaultShow !== undefined && (_jsx(TableRow, { children: _jsx(TableCell, { colSpan: headers === null || headers === void 0 ? void 0 : headers.length, sx: { textAlign: 'center' }, onClick: () => setExpanded(!expanded), style: { whiteSpace: nowrap ? 'nowrap' : 'normal' }, children: expanded ? (_jsx(ExpandLess, { sx: { height: 16 } })) : (_jsx(ExpandMore, { sx: { height: 16 } })) }) }))] })] }) }));
};
export default BasicTable;
