import {
  QueryClient,
  useMutation,
  useQuery,
  useQueries,
} from '@tanstack/react-query';
import { signInAnonymously, signOut } from 'firebase/auth';

import { impUser, addUid } from '@/services/helpers';
import { auth } from '@/firebase';
import { GlobalStateCodes } from '@/types';
import useFeVersionStore from '@/store/feVersioningStore';
import { getLocalData } from '@/utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';
import useAppStore from '@/store/appStore';
import { useIsImpersonatedUser } from '@/hooks/useIsImpersonatedUser';

type EndpointType =
  | 'accounts'
  | 'accounts/get_onboarding_accounts'
  | 'accounts/settings'
  | 'accounts/settings/companies'
  | 'accounts/settings/views-and-fields'
  | 'admin/accounts'
  | 'admin/documents'
  | 'admin/check'
  | 'admin/data-update'
  | 'admin/users'
  | 'admin/users/reset-user-password'
  | 'admin/users/verify_user_account'
  | 'admin/users/verify-user-account'
  | 'api_key'
  | 'auth_handler'
  | 'auth_handler/check-contact'
  | 'comments'
  | 'comp-grids'
  | 'comp-grids/criteria'
  | 'comp-grids/criteria/criteria-with-rates'
  | `comp-grids/import-rates`
  | 'comp-grids/levels'
  | 'comp-grids/products'
  | 'comp-grids/rates'
  | 'comp-grids/rates/bulk-edit'
  | 'comp-grids/rates/copy-rates'
  | 'comp-grids/view'
  | 'companies'
  | 'contacts'
  | 'contacts/contacts_without_user'
  | 'contacts/groups'
  | 'contacts/options'
  | 'data_imports'
  | `contacts/options?${any}`
  | `data-update/config?${any}`
  | `data-update/actions?${any}`
  | `data-update/criteria?${any}`
  | 'data_processing'
  | 'data_processing/commissions/agents'
  | 'data_processing/sync/benefit-point/statements'
  | 'data_processing/sync/nowcerts/policy'
  | 'data_processing/sync/synced-fields'
  | 'data_processing/sync/worker-synced-fields'
  | 'data_processing/sync/workers'
  | 'data_processing/sync/worker'
  | 'data_processing/sync'
  | 'date-ranges'
  | 'document_profiles'
  | 'documents'
  | 'documents/adobeExtract'
  | 'documents/nanonets'
  | 'documents/htmlExtract'
  | 'documents/googleDocumentAI'
  | 'documents/extractData'
  | 'documents/duplicateFilesDetector'
  | 'documents/document-classification'
  | 'dynamic_selects'
  | 'export/reconciliation_data'
  | 'extractions'
  | 'fields?model=reports'
  | 'fields?model=statements'
  | 'fields'
  | 'gpt'
  | 'gpt/doc'
  | 'history'
  | 'insights'
  | 'insights/layout'
  | 'insights/delete_dashboard'
  | 'insights/accountWidgetsSettings'
  | 'insights/preview'
  | 'login'
  | 'mappings'
  | 'metrics'
  | 'notifications'
  | 'processors'
  | 'processors/selector'
  | 'prompts'
  | 'prompts'
  | 'reconciliation_data'
  | 'report_data'
  | 'report_data/agents'
  | 'report_data/agent_comp_profiles_rates'
  | 'report_data/bulk_update'
  | 'report_data/transaction_type'
  | 'roles'
  | 'release/changelogs'
  | 'saved_reports'
  | 'saved_reports/groups/bulk_share'
  | 'saved_reports/groups/bulk_share'
  | 'saved_reports/groups/details'
  | 'saved_reports/groups/gen_commissions_report'
  | 'saved_reports/reports'
  | 'saved_reports/views'
  | 'schedules/agents'
  | 'schedules/agents/incentive_tiers'
  | 'schedules/agents/sets'
  | 'schedules/carriers'
  | 'schedules/comp_grids'
  | 'schedules/comp-profile-sets'
  | 'schedules/comp-profiles'
  | 'statement_data'
  | 'statement_data/carrier_name'
  | 'statement_data/compensation_type'
  | 'statement_data/export'
  | 'statement_data/filters'
  | 'statement_data/options'
  | 'statement_data/transaction_type'
  | 'storage/download'
  | 'storage/getSignedUrl'
  | 'storage/upload'
  | 'stripe/payment_links'
  | 'transactions/plaid/auth'
  | 'transactions/plaid/exchangePublicToken'
  | 'transactions/plaid/linkToken'
  | 'transactions/plaid/transactions'
  | 'users'
  | 'users/get_account_users'
  | 'users/get_fintary_admins'
  | 'users/get_fintary_csms'
  | 'users/invite_new_user'
  | 'users/invite_new_user/validate'
  | `companies?${any}`
  | `companies/${any}`
  | `companies/${any}`
  | `contacts/options?${any}`
  | `data_imports/${any}`
  | `extractions/${any}`
  | `processors/${any}`
  | 'v2/data_processing';

type ApiQueryType = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

const signInAnonymouslyEndpoints = [
  {
    endpoint: 'users/invite_new_user/validate',
    method: 'PATCH',
  },
];

const ANONYMOUS_TOKEN = 'anonymousToken';
const ANONYMOUS_ACCOUNT_ID = 'anonymousAccountId';
const ANONYMOUS_TOKEN_EXPIRY = 'anonymousTokenExpiry';
class API {
  public queryClient: QueryClient;
  constructor() {
    this.queryClient = new QueryClient();
  }

  static getEmbeddingQuery = (str) => ({
    queryKey: [str],
    queryFn: async () => {
      const res = await fetch(`${process.env.REACT_APP_AI}/encode`, {
        method: 'POST',
        headers: await this.getHeaders(),
        body: JSON.stringify({
          query: str,
        }),
      });
      return res.json();
    },
    enabled: !!auth?.currentUser || this.ssoTokenExists(),
  });

  static getEmbeddings = (strs) => {
    return useQueries({
      queries: strs.map((str) => this.getEmbeddingQuery(str)),
    });
  };

  static getHeaders = async () => {
    const idToken = await auth.currentUser?.getIdToken();
    const ssoToken = localStorage.getItem('sso-token');
    const selectedAccountId = getLocalData(
      LOCAL_STORAGE_KEYS.selectedAccount
    )?.accountId;
    const userRole = JSON.parse(localStorage.getItem('userRole') ?? '{}');

    return impUser({
      authentication: `Bearer ${idToken ? idToken : ssoToken}`,
      'content-type': 'application/json',
      accountid: selectedAccountId,
      userrole: userRole,
      feversion: __BUILD_TIME__,
    });
  };

  static getAnonymousHeaders = async () => {
    try {
      const tokenKey = ANONYMOUS_TOKEN;
      const accountIdKey = ANONYMOUS_ACCOUNT_ID;
      const tokenExpiryKey = ANONYMOUS_TOKEN_EXPIRY;

      const token = localStorage.getItem(tokenKey);
      const accountId = localStorage.getItem(accountIdKey);
      const tokenExpiry = localStorage.getItem(tokenExpiryKey);

      const isTokenValid =
        token && accountId && tokenExpiry && new Date(tokenExpiry) > new Date();

      if (!isTokenValid) {
        const userCredential = await signInAnonymously(auth);

        const user = userCredential.user;

        if (!user) {
          throw new Error('No user available after anonymous sign-in');
        }

        const newToken = await user.getIdToken();
        const newTokenExpiration = (await user.getIdTokenResult())
          .expirationTime;

        localStorage.setItem(tokenKey, newToken);
        localStorage.setItem(accountIdKey, user.uid);
        localStorage.setItem(tokenExpiryKey, newTokenExpiration);

        return {
          authentication: `Bearer ${newToken}`,
          'content-type': 'application/json',
          uid: user.uid,
          userrole: 'guest',
          feversion: __BUILD_TIME__,
        };
      }

      // Construct and return headers with the valid token
      return {
        authentication: `Bearer ${token}`,
        'content-type': 'application/json',
        accountid: accountId,
        userrole: 'guest',
        feversion: __BUILD_TIME__,
      };
    } catch (error) {
      console.error('Failed to get anonymous headers:', error);
      throw error;
    }
  };

  static signOutAnonymousUser = async () => {
    try {
      await signOut(auth);

      localStorage.removeItem(ANONYMOUS_TOKEN);
      localStorage.removeItem(ANONYMOUS_ACCOUNT_ID);
      localStorage.removeItem(ANONYMOUS_TOKEN_EXPIRY);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  static getUser = <T = any>() => {
    return useQuery<T>({
      queryKey: ['users'],
      queryFn: async () => {
        const res = await fetch(`${process.env.REACT_APP_API}/api/users`, {
          method: 'GET',
          headers: await this.getHeaders(),
        });
        return res.json();
      },
      enabled: !!auth?.currentUser || this.ssoTokenExists(),
    });
  };

  static getBasicQuery = <T = any>(
    endpoint: EndpointType,
    additionalQueryParams = '',
    enabled = true,
    options = {}
  ) => {
    const selectedAccountId = getLocalData(
      LOCAL_STORAGE_KEYS.selectedAccount
    )?.accountId;
    const { setFeVersion } = useFeVersionStore();
    const abortController = new AbortController();
    const abortReason = 'Request aborted';
    const query = useQuery<T>({
      queryKey: [selectedAccountId, endpoint, additionalQueryParams],
      queryFn: async () => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API}/api/${endpoint}${
              additionalQueryParams
                ? `${endpoint.includes('?') ? '&' : '?'}${additionalQueryParams}`
                : ''
            }`,
            {
              method: 'GET',
              signal: abortController.signal,
              headers: await this.getHeaders(),
            }
          );

          const data = await res.json();

          // If FE version is not updated just show a message to the user
          const outOfDate = res.headers.get('out_of_date');
          if (outOfDate && outOfDate === GlobalStateCodes.FE_OUT_OF_DATE) {
            setFeVersion({
              stateCode: GlobalStateCodes.FE_OUT_OF_DATE,
              message:
                'A new version of Fintary is available. Refresh this page to update.',
            });
          }

          // If FE version is incompatible with BE the response is empty
          if (
            res.status === 400 &&
            data?.error === GlobalStateCodes.FE_INCOMPATIBLE
          ) {
            setFeVersion({
              stateCode: GlobalStateCodes.FE_INCOMPATIBLE,
              message:
                'A new version of Fintary is required. Refresh this page to update.',
            });

            return '';
          }
          if (res.status >= 400) {
            throw new Error(data.message || data.error || data);
          }

          return data;
        } catch (err) {
          if (err === abortReason) {
            console.log(err);
            return;
          }
          throw err;
        }
      },
      enabled: enabled && (!!auth?.currentUser || this.ssoTokenExists()),
      ...options,
    });
    return { ...query, abort: () => abortController.abort(abortReason) };
  };

  static ssoTokenExists = () => {
    return !!localStorage.getItem(LOCAL_STORAGE_KEYS.ssoToken);
  };

  // Use fetch to get data from api
  static get = async (endpoint: EndpointType, additionalQueryParams = '') => {
    const res = fetch(
      `${process.env.REACT_APP_API}/api/${endpoint}${
        additionalQueryParams ? `?${additionalQueryParams}` : ''
      }`,
      {
        method: 'GET',
        headers: await this.getHeaders(),
      }
    );
    return (await res).json();
  };

  static getBasicQueryAll(
    endpoints: EndpointType[] = [],
    additionalQueryParams = '',
    enabled = true
  ) {
    // Ensure that endpoints is an array, if it's a string, convert it to array.
    if (typeof endpoints === 'string') {
      endpoints = [endpoints];
    }
    const queries = endpoints.map((endpoint) => ({
      queryKey: [endpoint, additionalQueryParams],
      queryFn: async () => {
        const res = await fetch(
          `${process.env.REACT_APP_API}/api/${endpoint}${
            additionalQueryParams ? `?${additionalQueryParams}` : ''
          }`,
          {
            method: 'GET',
            headers: await this.getHeaders(),
          }
        );
        return res.json();
      },
      enabled: enabled && (!!auth?.currentUser || this.ssoTokenExists()),
    }));

    return useQueries({ queries });
  }

  static getMutation = (
    endpoint: EndpointType,
    method: ApiQueryType,
    options?: {
      gcTime?: number;
      rawData?: boolean;
    }
  ) => {
    if (!['POST', 'PATCH', 'DELETE', 'PUT'].includes(method)) {
      throw new Error(`Unsupported method: ${method}`);
    }

    const isImpUser = useIsImpersonatedUser();
    const feVersion = useFeVersionStore((s) => s.feVersion);
    const abortController = new AbortController();
    const abortReason = 'Request aborted';
    const mutation = useMutation({
      gcTime: options?.gcTime,
      mutationFn: async (body: any) => {
        if (
          feVersion?.stateCode === GlobalStateCodes.FE_OUT_OF_DATE ||
          feVersion?.stateCode === GlobalStateCodes.FE_INCOMPATIBLE
        ) {
          console.warn(
            'FE version is not up to date. Please refresh the page.'
          );
          return {};
        }
        if (auth) {
          const validAnonymouslyEndpoint = signInAnonymouslyEndpoints.find(
            (item) => item.endpoint === endpoint && item.method === method
          );
          const baseHeader = validAnonymouslyEndpoint
            ? await this.getAnonymousHeaders()
            : await this.getHeaders();

          body = validAnonymouslyEndpoint
            ? {
                ...body,
                options: {
                  allowNoAccount: true,
                  doNotCheckRole: true,
                },
              }
            : body;
          try {
            if (isImpUser) {
              const updatesEnabled = useAppStore.getState().updatesEnabled;
              const allowedEndpoints = [
                'dynamic_selects',
                'storage/getSignedUrl',
                'storage/download',
                'processors/selector',
              ];
              if (!updatesEnabled && !allowedEndpoints.includes(endpoint)) {
                throw new Error(
                  'Updates are disabled. Toggle "Enable updates" to modify customer data.'
                );
              }
            }

            const res = await fetch(
              `${process.env.REACT_APP_API}/api/${endpoint}`,
              {
                signal: abortController.signal,
                method,
                headers: baseHeader,
                body: JSON.stringify(addUid(body)),
              }
            );
            if (!options?.rawData) {
              const data = await res.json();

              if (res.status >= 400) {
                if (data?.errObj) return data;
                throw new Error(data.message || data.error || data);
              }
              return data;
            } else {
              return res;
            }
          } catch (err) {
            if (err === abortReason) {
              console.log(err);
              return;
            }
            throw err;
          }
        }
        console.warn('not authed');
        return {};
      },
    });
    return { ...mutation, abort: () => abortController.abort(abortReason) };
  };
}

export default API;
