import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GetCustomersByNamesDTO } from 'common/customer/customer.validator';
import { CreateCustomerFromPolicies } from 'common/customer/customer.types';

const Prefix = `${process.env.REACT_APP_API}/api`;

export const API_PATHS = {
  customers: `${Prefix}/customers`,
  customer_create_from_policies: `${Prefix}/customers/create-customers-policies`,
  customer_search_name: `${Prefix}/customers/search_name`,
  get_customer_by_names: `${Prefix}/customers/get-customers-by-names`,
};

export const useCreateCustomersFromPolicies = () => {
  return useMutation({
    mutationKey: [API_PATHS.customer_create_from_policies],
    mutationFn: (
      data: Omit<
        CreateCustomerFromPolicies,
        | 'clientId'
        | 'dedupe_group_ids'
        | 'policies_ids'
        | 'dedupe_customers_names'
      >[]
    ) => {
      return axios.post(API_PATHS.customer_create_from_policies, data);
    },
  });
};

export const useCustomersByNames = (
  params: Omit<GetCustomersByNamesDTO, 'account_id'>
) => {
  return useQuery({
    queryKey: [API_PATHS.get_customer_by_names, params],
    queryFn: async () => {
      return axios
        .post(API_PATHS.get_customer_by_names, params)
        .then((res) => res.data);
    },
  });
};
