import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Customer } from 'common/customer/customer.types';

const Prefix = `${process.env.REACT_APP_API}/api`;

export const API_PATHS = {
  customers: `${Prefix}/customers`,
  customer_search_name: `${Prefix}/customers/search_name`,
  get_customer_by_names: `${Prefix}/customers/get-customers-by-names`,
};

export const useSearchCustomerName = ({ q }: { q: string }) => {
  return useQuery<Customer[]>({
    queryKey: [API_PATHS.customer_search_name, { q }],
    queryFn: async () => {
      return axios
        .get(API_PATHS.customer_search_name, { params: { q } })
        .then((res) => res.data);
    },
    enabled: !!q,
  });
};
