export enum DedupeValue {
  GroupId = 'group_id',
  InternalId = 'internal_id',
  Name = 'name',
}
export const DEDUPE_OPTIONS = [
  { name: 'Group ID', id: DedupeValue.GroupId },
  { name: 'Internal ID', id: DedupeValue.InternalId },
  { name: 'Name', id: DedupeValue.Name },
];
