import { GroupViewValues } from 'common/documents/documents.constants';
import { DateItem } from 'common/documents/documents.types';
import dayjs from 'dayjs';
import { useMemo } from 'react';

export const useProcessGroupBy = ({
  data,
  groupBy,
}: {
  data: DateItem[];
  groupBy: GroupViewValues;
}) => {
  return useMemo(() => {
    switch (groupBy) {
      case GroupViewValues.QUARTER: {
        // Key: quarter-year, value: total files
        // We use Map instead of object to preserve the order of the keys
        const groupedData = new Map<string, number>();
        data.forEach((item) => {
          const date = dayjs(item.date);
          const year = date.year();
          const month = date.month();
          const quarter = Math.floor(month / 3) + 1;
          const key = `Q${quarter}-${year}`;
          groupedData.set(key, (groupedData.get(key) || 0) + item.file_count);
        });

        const result: DateItem[] = [];
        groupedData.forEach((file_count, key) => {
          result.push({
            date: key,
            file_count,
          });
        });

        return result;
      }

      case GroupViewValues.YEAR: {
        const groupedData = new Map<string, number>();
        data.forEach((item) => {
          const date = dayjs(item.date);
          const year = date.year().toString();
          groupedData.set(year, (groupedData.get(year) || 0) + item.file_count);
        });
        const result: DateItem[] = [];
        groupedData.forEach((file_count, key) => {
          result.push({
            date: key,
            file_count,
          });
        });

        return result;
      }

      default:
        return data;
    }
  }, [data, groupBy]);
};
