var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'contexts/LoadingContext';
import Spreadsheet from '@/services/Spreadsheet';
import FilePreview from '@/common/preview';
import { XLS_CSV_TYPES } from '@/common/preview/model';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
const DocPreview = ({ path, previewWidth, previewFile, setPreviewFile, rowData, }) => {
    const [spreadsheet, setSpreadsheet] = useState();
    const { setLoadingConfig } = useContext(LoadingContext);
    const { downloadFile } = useDownloadStorageFile();
    useEffect(() => {
        const readFile = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!path)
                return null;
            setLoadingConfig({
                loading: true,
            });
            const file = yield downloadFile({
                endpoint_str_id: rowData === null || rowData === void 0 ? void 0 : rowData.str_id,
                file_preview_type: 'original',
                endpoint: 'documents',
            });
            setPreviewFile(file);
            setLoadingConfig({ loading: false });
        });
        if (path) {
            readFile();
        }
    }, [path, rowData]);
    useEffect(() => {
        const setExcelData = () => __awaiter(void 0, void 0, void 0, function* () {
            if (previewFile && XLS_CSV_TYPES.includes(previewFile.type)) {
                const res = yield Spreadsheet.loadSpreadsheet(previewFile);
                setSpreadsheet(res);
            }
        });
        setExcelData();
    }, [previewFile]);
    return (_jsx(FilePreview, { previewFile: previewFile, previewWidth: previewWidth, 
        // @ts-ignore
        spreadsheet: spreadsheet, 
        // @ts-ignore
        setSpreadsheet: setSpreadsheet }));
};
export default DocPreview;
