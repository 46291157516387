import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, } from '@mui/material';
import { DocumentPreviewTypes } from '@/types';
const METHOD_LABELS = {
    extractTable: 'Extract Table',
    documentAI: 'Google Document AI',
    adobeExtract: 'Adobe PDF Extract',
    nanonets: 'Nanonets',
    htmlExtract: 'HTML Extract',
    gemini: 'Gemini',
};
const DEFAULT_METHODS = {
    pdf: 'extractTable',
    html: 'htmlExtract',
};
const NEW_PDF_EXTRACTION_OPTIONS = [
    {
        value: 'extractTable',
        label: 'Extract Table',
    },
    {
        value: 'nanonets',
        label: 'Nanonets',
    },
    {
        value: 'adobeExtract',
        label: 'Adobe PDF Extract',
    },
    {
        value: 'documentAI',
        label: 'Google Document AI',
    },
    {
        value: 'gemini',
        label: 'Gemini',
    },
];
const NEW_HTML_EXTRACTION_OPTIONS = [
    {
        value: 'htmlExtract',
        label: 'HTML Extract',
    },
    {
        value: 'gemini',
        label: 'Gemini',
    },
];
const getExtractionOptions = (fileType) => fileType === DocumentPreviewTypes.HTML
    ? NEW_HTML_EXTRACTION_OPTIONS
    : NEW_PDF_EXTRACTION_OPTIONS;
const ExtractMethod = ({ showExtract, onClose, onConfirm, uploadedRow }) => {
    const [selectedMethod, setSelectedMethod] = useState(DEFAULT_METHODS.pdf);
    const newExtractionOptions = useMemo(() => getExtractionOptions(uploadedRow.file_type), [uploadedRow.file_type]);
    useEffect(() => {
        if (uploadedRow.file_type === DocumentPreviewTypes.HTML) {
            setSelectedMethod(DEFAULT_METHODS.html);
        }
        else {
            setSelectedMethod(DEFAULT_METHODS.pdf);
        }
    }, [uploadedRow.file_type]);
    // Prepend existing extractions to list of options.
    // Hack: Using ::str_id::id in value to denote that we want to load an existing extraction with str_id
    const previousExtractions = useMemo(() => {
        var _a;
        const prevExtractions = ((_a = uploadedRow.extractions) !== null && _a !== void 0 ? _a : [])
            .filter((item) => {
            if (item.method === DEFAULT_METHODS.pdf) {
                return item.result;
            }
            else {
                return true;
            }
        })
            .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
            .map((e) => ({
            label: (_jsxs(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }, children: [_jsx(Box, { children: METHOD_LABELS[e.method] }), _jsx(Box, { children: new Date(e.created_at).toLocaleString() })] })),
            value: `${e.method}::${e.str_id}::${e.id}`,
        }));
        return prevExtractions.length
            ? [
                {
                    label: (_jsx(Box, { sx: { textAlign: 'center', width: '100%' }, children: "Use existing extraction" })),
                    value: 'divider1',
                    disabled: true,
                    sx: { textAlign: 'center' },
                },
                ...prevExtractions,
                {
                    label: (_jsx(Box, { sx: { textAlign: 'center', width: '100%' }, children: "Create new extraction" })),
                    value: 'divider2',
                    disabled: true,
                },
                ...newExtractionOptions,
            ]
            : newExtractionOptions;
    }, [uploadedRow]);
    useEffect(() => {
        var _a;
        if (((_a = uploadedRow.extractions) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const mostRecent = uploadedRow.extractions.find((item) => item.status === 'extracted') ||
                uploadedRow.extractions[0];
            setSelectedMethod(`${mostRecent.method}::${mostRecent.str_id}::${mostRecent.id}`);
        }
    }, [uploadedRow.extractions]);
    const handleSelectionChange = (event) => {
        setSelectedMethod(event.target.value);
    };
    const handleSubmit = () => {
        onConfirm(selectedMethod);
    };
    return (_jsxs(Dialog, { open: showExtract, onClose: onClose, fullWidth: true, children: [_jsx(DialogTitle, { children: "Document data extraction method" }), _jsx(DialogContent, { children: _jsx(Box, { children: _jsx(Select, { fullWidth: true, value: selectedMethod, onChange: handleSelectionChange, children: previousExtractions.map((item) => (_jsx(MenuItem, { value: item.value, disabled: item.disabled, sx: item.sx, children: item.label }, item.value))) }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: handleSubmit, disabled: !selectedMethod || !previousExtractions.length, variant: "contained", children: newExtractionOptions.map((e) => e.value).includes(selectedMethod)
                            ? 'Extract data'
                            : 'View data' })] })] }));
};
ExtractMethod.propTypes = {
    showExtract: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    uploadedRow: PropTypes.object,
};
export default ExtractMethod;
