import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MoreVert } from '@mui/icons-material';
import { Card, CardContent, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, } from '@mui/material';
import React, { useContext, useState } from 'react';
import BasicTable from '@/components/molecules/BasicTable';
import { exportCsv } from '@/services/helpers';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
const WidgetWrapper = ({ displayName, onEdit, onDelete, children, onCopy, data: _data, widgetData, type, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [anchorEl, setAnchorEl] = useState(null);
    const [view, setView] = useState('widget');
    const { role: [role], } = useContext(UIStateContext);
    const isFinatryAdmin = role === 'admin';
    const { userRole } = useRoleStore();
    const isAccountAdmin = userRole === Roles.ACCOUNT_ADMIN;
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    let headers;
    let data;
    if (type === 'chart-donut') {
        headers = Object.keys(((_c = (_b = (_a = _data === null || _data === void 0 ? void 0 : _data.series) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0]) || {});
        data = (_f = (_e = (_d = _data === null || _data === void 0 ? void 0 : _data.series) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.map((row) => {
            return Object.values(row);
        });
    }
    else if (type === 'chart-bar') {
        headers = ['key', 'value'];
        data = _data === null || _data === void 0 ? void 0 : _data.xAxis.data.map((key, i) => {
            var _a, _b;
            return [
                key,
                (_b = (_a = _data === null || _data === void 0 ? void 0 : _data.series) === null || _a === void 0 ? void 0 : _a[0].data) === null || _b === void 0 ? void 0 : _b[i],
            ];
        });
    }
    else if (type === 'chart-line') {
        headers = ['key', 'value'];
        data = _data === null || _data === void 0 ? void 0 : _data.xAxis.data.map((key, i) => {
            var _a, _b;
            return [
                key,
                (_b = (_a = _data === null || _data === void 0 ? void 0 : _data.series) === null || _a === void 0 ? void 0 : _a[0].data) === null || _b === void 0 ? void 0 : _b[i],
            ];
        });
    }
    else if (type === 'table-array') {
        headers = _data ? _data[0] : [];
        data = _data ? _data.slice(1) : [];
    }
    else if (type === 'h5') {
        headers = [displayName];
        console.log(_data);
        data = _data.toString();
    }
    const dataSourceDisplayMap = (dataSource) => {
        console.log(dataSource);
        switch (dataSource) {
            case 'statement':
                return 'Commission';
            case 'report':
                return 'Policy';
            case 'reconciliation':
                return 'Reconciliation';
            case 'reportDataCommissionProcessingDate':
                return 'Policy';
            default:
                return dataSource;
        }
    };
    return (_jsx(Card, { style: { height: '100%', overflowY: 'scroll' }, children: _jsxs(CardContent, { sx: { pb: 0, height: '90%' }, children: [_jsxs(Grid, { container: true, justifyContent: "space-between", className: "grid-item__title", children: [_jsx(Grid, { item: true, style: { width: '80%' }, className: "dragHandle", children: _jsx(Tooltip, { title: dataSourceDisplayMap((_g = widgetData === null || widgetData === void 0 ? void 0 : widgetData.spec) === null || _g === void 0 ? void 0 : _g.dataSource), children: _jsx(Typography, { gutterBottom: true, variant: "body1", children: displayName }) }) }), _jsxs(Grid, { item: true, children: [_jsx(IconButton, { onClick: handleMenuClick, children: _jsx(MoreVert, {}) }), _jsxs(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, children: [(isAccountAdmin || isFinatryAdmin) && [
                                            onEdit && (_jsx(MenuItem, { onClick: () => {
                                                    handleMenuClose();
                                                    onEdit();
                                                }, children: "Edit" }, "edit")),
                                            onDelete && (_jsx(MenuItem, { onClick: () => {
                                                    handleMenuClose();
                                                    onDelete();
                                                }, children: "Delete" }, "delete")),
                                        ], onCopy && (_jsx(MenuItem, { onClick: () => {
                                                handleMenuClose();
                                                onCopy(widgetData.spec);
                                            }, children: "Create copy" }, "copy")), typeof type === 'string' &&
                                            ['chart-donut', 'chart-bar'].includes(type) && [
                                            _jsx(MenuItem, { onClick: () => {
                                                    setView(view === 'table' ? 'widget' : 'table');
                                                }, children: view === 'table' ? 'Widget view' : 'Table view' }, "toggleView"),
                                        ], _jsx(MenuItem, { onClick: () => {
                                                exportCsv(headers, data, 'Fintary-Export.csv');
                                            }, children: "Export" }, "export")] })] })] }), view === 'widget' && children, view === 'table' && (_jsx(BasicTable, { headers: headers, rows: data, formatters: {} }))] }) }));
};
export default WidgetWrapper;
