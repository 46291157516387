import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ApiPolicyResponse } from 'common/policies/policies.types';
import { Pagination } from 'common/types/pagination';

const prefix = `${process.env.REACT_APP_API}/api`;
const API_PATHS = {
  report_data_customer_names: `${prefix}/report_data/customer_names`,
};

export const usePoliciesWithoutCustomerIds = (params: Pagination) => {
  return useQuery<ApiPolicyResponse>({
    queryKey: [API_PATHS.report_data_customer_names, params],
    queryFn: async () => {
      return axios
        .get(API_PATHS.report_data_customer_names, {
          params,
        })
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
  });
};
