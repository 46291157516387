export var GroupViewValues;
(function (GroupViewValues) {
    GroupViewValues["MONTH"] = "month";
    GroupViewValues["QUARTER"] = "quarter";
    GroupViewValues["YEAR"] = "year";
})(GroupViewValues || (GroupViewValues = {}));
export const GroupViewOptions = [
    { id: GroupViewValues.MONTH, name: 'Month' },
    { id: GroupViewValues.QUARTER, name: 'Quarter' },
    { id: GroupViewValues.YEAR, name: 'Year' },
];
export const GROUP_BY_KEY = 'group_by';
