import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { EnhancedSelect } from '../molecules/EnhancedSelect';
export const AgentSelect = ({ updateAgentData, dynamicSelects, agentDataToUse, removeAgentData, }) => {
    const { selectedAccount } = useAccountStore();
    const options = useMemo(() => {
        return dynamicSelects.map((item) => {
            return Object.assign(Object.assign({}, item), { label: Formatter.contact(item, {
                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                }) });
        });
    }, [dynamicSelects, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    const value = useMemo(() => {
        const _set = new Set(Object.keys(agentDataToUse));
        return options.filter((item) => _set.has(item.str_id));
    }, [agentDataToUse, options]);
    const onChange = (v) => {
        const createMap = (list) => {
            return list.reduce((prev, cur) => {
                prev[cur.str_id] = 1;
                return prev;
            }, {});
        };
        const valueMap = createMap(value);
        const newValueMap = createMap(v);
        // Adding new keys
        v.forEach((item) => {
            if (!valueMap[item.str_id])
                updateAgentData(item.str_id, '0');
        });
        // Remove keys
        value.map((item) => {
            if (!newValueMap[item.str_id])
                removeAgentData(item.str_id);
        });
    };
    return (_jsx(EnhancedSelect, { label: value.length ? '' : 'Add', enableSelectAllSearchResult: false, disableAllOption: true, enableSearch: true, multiple: true, options: options, valueKey: "str_id", labelKey: "label", value: value, onChange: onChange }));
};
