import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { FiltersOperators } from 'common/globalTypes';
import { FieldTypes } from '@/types';
import FieldMatcherComponent from '@/components/molecules/FieldMatcherComponent';
import { EnhancedSelect } from './EnhancedSelect';
const isNumeric = (type) => [FieldTypes.CURRENCY, FieldTypes.PERCENTAGE, FieldTypes.INTEGER].includes(type);
const isDate = (type) => {
    return [FieldTypes.DATE].includes(type);
};
// TODO: Add validations, enums and conditional rendering for options based on field types
export const fieldMatcherOptions = [
    { id: FiltersOperators.EQ, label: 'Equals', shortLabel: '=' },
    { id: FiltersOperators.NEQ, label: 'Not equals', shortLabel: '!=' },
    {
        id: FiltersOperators.GT,
        label: 'Greater than',
        shortLabel: '>',
        validate: isNumeric,
    },
    {
        id: FiltersOperators.LT,
        label: 'Less than',
        shortLabel: '<',
        validate: isNumeric,
    },
    {
        id: FiltersOperators.GTE,
        label: 'Greater than or equals',
        shortLabel: '>=',
        validate: isNumeric,
    },
    {
        id: FiltersOperators.LTE,
        label: 'Less than or equals',
        shortLabel: '>=',
        validate: isNumeric,
    },
    { id: FiltersOperators.CONTAINS, label: 'Contains' },
    { id: FiltersOperators.NCONTAINS, label: 'Not contains' },
    { id: FiltersOperators.CONTAINEDIN, label: 'Contained in' },
    { id: FiltersOperators.NCONTAINEDIN, label: 'Not contained in' },
    { id: FiltersOperators.STARTSWITH, label: 'Starts with' },
    { id: FiltersOperators.ENDSWITH, label: 'Ends with' },
    { id: FiltersOperators.BEFORE, label: 'Before', validate: isDate },
    {
        id: FiltersOperators.BEFORE_EQUALS,
        label: 'Before or equals',
        validate: isDate,
    },
    { id: FiltersOperators.AFTER, label: 'After', validate: isDate },
    {
        id: FiltersOperators.AFTER_EQUALS,
        label: 'After or equals',
        validate: isDate,
    },
    { id: FiltersOperators.IS_EMPTY, label: 'Is empty' },
    { id: FiltersOperators.WITHIN_ONE_YEAR, label: 'Within one year' },
    { id: FiltersOperators.AT_LEAST_ONE_YEAR, label: 'At least one year' },
    { id: FiltersOperators.CUSTOM, label: 'Unset date range' },
];
const FieldMatcher = ({ fields, value: fieldsMatchers = [], setValue, addLabel = 'Add', hideUsePolicyData = false, sx = {}, }) => {
    const getSelectedField = (fieldId) => {
        return fields.find((f) => f.id === fieldId);
    };
    const addtionalOptions = [
        { name: 'Case sensitive ', id: 'caseSensitive' },
        { name: 'Skip empty', id: 'skipEmpty' },
    ];
    if (!hideUsePolicyData) {
        addtionalOptions.push({ name: 'Use policy data', id: 'usePolicyData' });
    }
    return (_jsxs(Box, { sx: Object.assign({}, sx), children: [fieldsMatchers === null || fieldsMatchers === void 0 ? void 0 : fieldsMatchers.map((fieldMatcher, i) => fieldMatcher.type !== 'Action' && (_jsxs(Box, { sx: {
                    mt: 1,
                    display: 'flex',
                    flexWrap: 'no-wrap',
                    width: '100%',
                    alignItems: 'center',
                }, children: [_jsx(EnhancedSelect, { enableSearch: true, label: "Field", options: fields === null || fields === void 0 ? void 0 : fields.sort((a, b) => (b.label > a.label ? -1 : 1)), labelKey: "label", value: fields.find((item) => item.id === fieldMatcher.field), onChange: (item) => {
                            const newFieldMatchers = [...fieldsMatchers];
                            newFieldMatchers[i].field = item.id;
                            setValue(newFieldMatchers);
                        }, sx: { marginRight: 1 } }), _jsx(EnhancedSelect, { label: "Operation", options: fieldMatcherOptions.filter((op) => {
                            var _a;
                            const selectedField = getSelectedField((_a = fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.field) === null || _a === void 0 ? void 0 : _a.id);
                            if (op.validate &&
                                selectedField &&
                                !op.validate(selectedField === null || selectedField === void 0 ? void 0 : selectedField.type)) {
                                return false;
                            }
                            return true;
                        }), labelKey: "label", value: fieldMatcherOptions.find((item) => item.id === fieldMatcher.op), onChange: (item) => {
                            const newFieldMatchers = [...fieldsMatchers];
                            newFieldMatchers[i].op = item.id;
                            setValue(newFieldMatchers);
                        }, sx: { marginRight: 1 } }), _jsx(FieldMatcherComponent, { fields: fields, fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue }, i), _jsx(EnhancedSelect, { multiple: true, label: "Options", options: addtionalOptions, value: addtionalOptions.filter((item) => {
                            return fieldsMatchers[i][item.id];
                        }), onChange: (v) => {
                            const newFieldMatchers = [...fieldsMatchers];
                            addtionalOptions.forEach((item) => {
                                newFieldMatchers[i][item.id] = false;
                            });
                            v.forEach((item) => {
                                newFieldMatchers[i][item.id] = true;
                            });
                            setValue(newFieldMatchers);
                        } }), _jsx(Box, { children: _jsx(IconButton, { onClick: () => {
                                const newFieldMatchers = [...fieldsMatchers];
                                newFieldMatchers.splice(i, 1);
                                setValue(newFieldMatchers);
                            }, children: _jsx(RemoveCircleOutline, {}) }) })] }, i))), _jsx(Button, { onClick: () => setValue([...fieldsMatchers, {}]), sx: { mt: 0.5 }, children: addLabel })] }));
};
export default FieldMatcher;
