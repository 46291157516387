import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, } from '@mui/material';
import { useState } from 'react';
const DataPreviewTable = ({ label, data, fields, collectionVals = [], opts = { expanded: false }, }) => {
    var _a, _b;
    const [expanded, setExpanded] = useState((_a = opts === null || opts === void 0 ? void 0 : opts.expanded) !== null && _a !== void 0 ? _a : false);
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Typography, { variant: "subtitle1", children: `${label} (${(_b = data === null || data === void 0 ? void 0 : data.length) !== null && _b !== void 0 ? _b : 0})` }), (data === null || data === void 0 ? void 0 : data.length) > 0 && (_jsx(IconButton, { onClick: () => {
                            setExpanded(!expanded);
                        }, children: expanded ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}) }))] }), expanded && Array.isArray(data) && data.length > 0 ? (_jsx(Box, { sx: {
                    border: '1px solid #ccc',
                    borderRadius: 4,
                    pt: 0.5,
                    width: '100%',
                    overflow: 'hidden',
                }, children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: fields.map((col) => (_jsx(TableCell, { children: col.label }, col.key))) }) }), _jsx(TableBody, { children: Array.isArray(data)
                                ? data.map((val) => (_jsx(TableRow, { children: fields.map((col) => (_jsx(TableCell, { children: typeof col.formatter === 'function'
                                            ? col.formatter(val[col.key], collectionVals)
                                            : val[col.key] }, col.key))) }, val.str_id)))
                                : null })] }) })) : null] }, "commission_preview"));
};
export default DataPreviewTable;
