import { useEffect } from 'react';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import * as Sentry from '@sentry/react';

import authentication from '@/services/authentication';

export const useAuth = (
  setUser: (user: any) => void,
  setLoginEmail: (email: string) => void,
  setAuthError: (error: any) => void
) => {
  useEffect(() => {
    const auth = getAuth();

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailAddress');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email ?? '', window.location.href)
        .then(() => {
          window.localStorage.removeItem('emailAddress');
        })
        .catch((error) => {
          console.error('Error when loggin in with email link', error);
          const { message } = error;
          setAuthError(message);
          Sentry.captureException(error);
        });
    }

    const onAuthStateChangedObserver = auth.onAuthStateChanged(
      (user) => {
        if (user && !user.isAnonymous) {
          setLoginEmail(user.emailVerified ? '' : user.email || '');
          setUser(user);
        } else {
          setUser(null);
        }
      },
      (error) => {
        console.error('Error when changing auth state', error);
        const { message } = error;
        setAuthError(message);
        Sentry.captureException(error);
      }
    );

    return () => {
      if (onAuthStateChangedObserver) {
        onAuthStateChangedObserver();
      }
    };
  }, []);

  const signOut = (callback: () => void) => {
    authentication
      .signOut()
      .then(() => {
        callback();
      })
      .catch((reason) => {
        const { message } = reason;
        setAuthError(message);
        Sentry.captureException(reason);
      });
  };

  const signOutExistingUser = (callback: () => void) => {
    authentication
      .signOutExistingUser()
      .then(() => {
        callback();
      })
      .catch((reason) => {
        const { message } = reason;
        setAuthError(message);
        Sentry.captureException(reason);
      });
  };

  return { signOut, signOutExistingUser };
};
