var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Add, Delete, Edit, LaunchOutlined } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { ProcessorReviewStatusesLabels } from 'common/globalTypes';
import { Link } from 'react-router-dom';
import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import ProcessorModal from '@/views/ProcessorPlayground/ProcessorModal';
import Formatter from '@/services/Formatter';
import { headerSx, cellSx, } from '@/components/HoverActionButtonContainer/styles';
import { HoverActionButtonContainer } from '@/components/HoverActionButtonContainer';
const ProcessorsView = ({ variant = '' }) => {
    const [showModal, setShowModal] = useState(false);
    /**
     * @type {Object}
     */
    const [currentRow, setCurrentRow] = useState(null);
    const [refresh, setRefresh] = useState(0);
    // Show comfirm dialog when deleting
    const [showConfirm, setShowConfirm] = useState(false);
    const deleter = API.getMutation('processors', 'DELETE');
    const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');
    const { setLoadingConfig } = useContext(LoadingContext);
    const statusFormatter = (s, row) => {
        const statusChip = Formatter.statusChip(ProcessorReviewStatusesLabels[s], {
            mapping: {
                [ProcessorReviewStatusesLabels.approved]: 'green',
                [ProcessorReviewStatusesLabels.in_review]: 'yellow',
                [ProcessorReviewStatusesLabels.needs_update]: 'red',
            },
        });
        return (_jsxs(_Fragment, { children: [statusChip, row.processor_status === 'processed' && (_jsx(Tooltip, { title: "This processor is already used to process a document. If you update it now, we will create a new processor for you so that it will not affect the already processed document.", children: _jsx(Chip, { sx: {
                            color: '#014361',
                            backgroundColor: '#e5f1fd',
                            borderRadius: 1,
                            m: 0.25,
                        }, size: "small", label: "In use" }) }))] }));
    };
    const extraActions = (_jsx(Button, { variant: "contained", startIcon: _jsx(Add, {}), onClick: () => {
            setCurrentRow(null);
            setShowModal(true);
        }, children: "Add" }));
    const dataDesc = {
        label: 'Processors',
        table: 'processors',
        editable: false,
        fields: [
            {
                id: 'type',
                label: 'Type',
                type: 'select',
                options: ['statement', 'report'],
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'company_id',
                label: 'Company',
                formatter: (s, row) => { var _a; return (s ? (_a = row.companies) === null || _a === void 0 ? void 0 : _a.company_name : ''); },
            },
            {
                id: 'documents',
                label: 'Documents',
                formatter: (count, row) => (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Box, { sx: { flex: 1, px: 2 }, children: _jsx("span", { children: count || 0 }) }), _jsx(Box, { children: count > 0 && (_jsx(IconButton, { component: Link, to: `/admin/documents/documents?q=${row.str_id}`, target: "_blank", sx: {
                                    opacity: 0.5,
                                    '&:hover': { opacity: 1 },
                                    color: '#2196f3',
                                }, children: _jsx(LaunchOutlined, {}) })) })] })),
            },
            {
                id: 'method',
                label: 'Method',
            },
            {
                id: 'access',
                label: 'Access',
                access: 'admin',
            },
            {
                id: 'owner',
                label: 'Owner',
                formatter: (s) => {
                    const target = owners === null || owners === void 0 ? void 0 : owners.find((c) => c.uid === s);
                    return Formatter.contact(target);
                },
            },
            {
                id: 'status',
                label: 'Status',
                access: 'admin',
                formatter: statusFormatter,
            },
            {
                id: 'reviewer_str_id',
                label: 'Reviewer',
                access: 'admin',
                formatter: (s, row) => s ? Formatter.contact(row.users_processors_reviewed_byTousers) : '',
            },
            {
                id: 'updated_at',
                label: 'Last updated',
                formatter: (s) => Formatter.date(s, { format: 'MM/DD/YY hh:mmA' }),
                readOnly: true,
            },
            {
                id: 'id',
                label: 'Actions',
                access: 'admin',
                sx: cellSx,
                headerSx,
                /**
                 *
                 * @param {number} id
                 * @param {*} row
                 * @returns
                 */
                formatter: (id, row) => (_jsxs(HoverActionButtonContainer, { children: [_jsx(IconButton, { "aria-label": "edit", onClick: () => {
                                setCurrentRow(row);
                                setShowModal(true);
                            }, children: _jsx(Edit, {}) }), _jsx(IconButton, { "aria-label": "delete", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setCurrentRow(row);
                                setShowConfirm(true);
                            }), children: _jsx(Delete, {}) })] })),
            },
        ],
    };
    const closeModal = (e, reason) => {
        if (reason === 'backdropClick')
            return;
        setShowModal(false);
        setRefresh(refresh + 1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataView, { dataDesc: dataDesc, viewOnly: true, extraActions: extraActions, refresh: refresh, enableAutoRefetch: true, variant: variant }), showModal && (_jsx(ProcessorModal, { handleCancel: closeModal, open: showModal, rowData: currentRow })), showConfirm && (_jsx(BasicDialog, { open: showConfirm, bodyComponent: _jsxs("div", { children: [_jsxs("h4", { children: ["Are you sure you want to delete the processor code", ' ', _jsx("span", { className: "text-blue-500 pr-1", children: (currentRow === null || currentRow === void 0 ? void 0 : currentRow.name) || '' }), "?"] }), _jsx("p", { className: "text-sm", children: "\u2757\u2757\u2757 This action cannot be undone." })] }), title: "Processor Delete", onClose: (val) => __awaiter(void 0, void 0, void 0, function* () {
                    setShowConfirm(false);
                    if (val) {
                        setLoadingConfig({
                            loading: true,
                            message: 'Deleting...',
                        });
                        yield deleter.mutateAsync({ ids: [currentRow.id] });
                        setRefresh(refresh + 1);
                        setLoadingConfig({
                            loading: false,
                            delayToClose: 1000,
                        });
                    }
                }) }))] }));
};
export default ProcessorsView;
