import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, } from '@mui/material';
import { nanoid } from 'nanoid';
import { useMemo, useState } from 'react';
import validator from 'validator';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const CompProfilesAdd = ({ data, field, setter, dynamicSelects, readOnly = false, table, }) => {
    var _a;
    const { selectedAccount } = useAccountStore();
    const [visibleProfilesCount, setVisibleProfilesCount] = useState(5);
    const profilesWithDates = (_a = data === null || data === void 0 ? void 0 : data[table]) !== null && _a !== void 0 ? _a : [];
    profilesWithDates.forEach((profileWithDates) => {
        if (!profileWithDates.contact_id.toString().includes('::'))
            profileWithDates.contact_id =
                profileWithDates.contact_id + '::' + profileWithDates.str_id;
    });
    const showMoreProfiles = () => {
        setVisibleProfilesCount((prevCount) => prevCount + 100);
    };
    const options = useMemo(() => {
        return ((dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.map((option) => {
            const label = `${Formatter.contact(option, { incl_email: true, account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId })}${Array.isArray(option === null || option === void 0 ? void 0 : option.contact_level) && (option === null || option === void 0 ? void 0 : option.contact_level.length) > 0 ? ` • ${option === null || option === void 0 ? void 0 : option.contact_level.map((l) => l.level_label).join(', ')}` : ''}`;
            return Object.assign(Object.assign({}, option), { label });
        })) || []);
    }, [dynamicSelects, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    const optionsMap = useMemo(() => {
        return ((options === null || options === void 0 ? void 0 : options.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {})) || {});
    }, [options]);
    const selectedOptions = useMemo(() => {
        var _a;
        const selectedValues = [];
        (_a = data[table]) === null || _a === void 0 ? void 0 : _a.forEach((item) => {
            selectedValues.push(optionsMap[item.contact.id]);
        });
        return selectedValues.filter(Boolean);
    }, [data, optionsMap, table]);
    console.log('options', options);
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(EnhancedSelect, { sx: { my: 1 }, enableSearch: true, 
                // Do not enable these 2 options disableAllOption and enableSelectAllSearchResult
                // because the list is too long and rendering list of form below will lag the ui
                disableAllOption: true, enableSelectAllSearchResult: false, label: "Select Agents", multiple: true, options: options, labelKey: "label", value: selectedOptions, listContainerSx: { minWidth: 500 }, onChange: (v) => {
                    const newData = v.map((item) => {
                        const id = item.id;
                        return {
                            contact: optionsMap[id],
                            contact_id: id + '::' + nanoid(),
                            start_date: null,
                            end_date: null,
                            multiplier: '100',
                        };
                    });
                    setter(Object.assign(Object.assign({}, data), { [table]: newData }));
                } }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        pl: 0.5,
                        py: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: _jsxs(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, children: [profilesWithDates
                                .slice(0, visibleProfilesCount)
                                .map((profileWithDates) => {
                                var _a;
                                return (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        p: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                        width: 330,
                                        backgroundColor: '#2196f30a',
                                    }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: Formatter.contact(profileWithDates.contact, {
                                                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                    }) }), _jsx(IconButton, { onClick: () => {
                                                        setter(Object.assign(Object.assign({}, data), { [table]: data[table].filter((item) => item.contact_id !== profileWithDates.contact_id) }));
                                                    }, disabled: readOnly, sx: { p: 0 }, children: _jsx(CloseIcon, {}) })] }), _jsx(Box, { children: _jsx(BasicDateRangePicker, { range: {
                                                    startDate: profileWithDates.start_date,
                                                    startDateLabel: 'Start date',
                                                    disableStartDatePicker: readOnly,
                                                    endDate: profileWithDates.end_date,
                                                    endDateLabel: 'End date',
                                                    disableEndDatePicker: readOnly,
                                                }, onChange: ({ startDate, endDate }) => {
                                                    setter(Object.assign(Object.assign({}, data), { [table]: data[table].map((item) => item.contact_id === profileWithDates.contact_id
                                                            ? Object.assign(Object.assign({}, item), { start_date: startDate, end_date: endDate }) : item) }));
                                                }, mt: 0.5, my: 0.5 }) }), _jsx(Box, { children: _jsx(TextField, { label: "Multiplier", value: (_a = profileWithDates.multiplier) !== null && _a !== void 0 ? _a : '100', sx: { mt: 0.5, width: '50%' }, InputProps: {
                                                    endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                                }, onChange: (e) => {
                                                    setter(Object.assign(Object.assign({}, data), { [table]: data[table].map((item) => item.contact_id === profileWithDates.contact_id
                                                            ? Object.assign(Object.assign({}, item), { multiplier: e.target.value }) : item) }));
                                                }, error: profileWithDates.multiplier &&
                                                    !validator.isFloat(profileWithDates.multiplier.trim(), {
                                                        min: 0,
                                                        max: 1000,
                                                    }), helperText: profileWithDates.multiplier &&
                                                    !validator.isFloat(profileWithDates.multiplier.trim(), {
                                                        min: 0,
                                                        max: 1000,
                                                    })
                                                    ? 'Must be a number between 0 and 1000'
                                                    : '', disabled: readOnly }) })] }, profileWithDates.contact_id));
                            }), profilesWithDates.length > visibleProfilesCount && (_jsx(Box, { sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 0.75,
                                    mr: 1,
                                }, children: _jsx(Box, { children: _jsxs(Button, { onClick: showMoreProfiles, variant: "outlined", children: ["Show more (", `${profilesWithDates.length - visibleProfilesCount}`, ")"] }) }) })), _jsx(Box, { sx: { mt: 0.5 }, children: _jsx(Button, { onClick: () => {
                                        setter(Object.assign(Object.assign({}, data), { [table]: [] }));
                                    }, children: "Clear" }) })] }) }) }, `${field.id}-box`)] }));
};
export default CompProfilesAdd;
