var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, FormHelperText, TextField, Typography, } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import Formatter from 'common/Formatter';
import { useContext, useMemo, useState } from 'react';
import { UserErrorCodes, UserErrorMessages, } from 'common/user-management/error-codes';
import { LoadingContext } from '@/contexts/LoadingContext';
import { useAccountStore } from '@/store';
import useSnackbar from '@/contexts/useSnackbar';
import { useInviteForm } from './validator';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import { useAgentList, useInviteUser, useRoles } from './apis';
import { Roles } from '@/types';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: 4,
};
const InviteUserForm = ({ open, handleClose, }) => {
    var _a, _b;
    const { errors, register, handleSubmit, setValue, watch } = useInviteForm();
    const [searchAgent, setSearchAgent] = useState('');
    const { showSnackbar } = useSnackbar();
    const { setLoadingConfig } = useContext(LoadingContext);
    const { selectedAccount } = useAccountStore();
    const { data: roles } = useRoles();
    const mutation = useInviteUser();
    const selectedRoleId = watch('roleId');
    const displayAgent = selectedRoleId === Roles.PRODUCER;
    const { data: agentData } = useAgentList(displayAgent);
    const selectedAgentId = watch('agentId');
    const handleInvite = (submitData) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) {
            setLoadingConfig({
                loading: true,
                message: 'Inviting new user...',
            });
            mutation.mutate(Object.assign({ accountId: selectedAccount.accountId, accountName: selectedAccount.accountName || '' }, submitData), {
                onSuccess: () => {
                    handleClose();
                    setLoadingConfig({
                        loading: false,
                        message: '',
                        delayToClose: 0,
                    });
                    showSnackbar('User invited');
                    mutation.reset();
                },
                onError: (err) => {
                    const { code } = err;
                    setLoadingConfig({
                        loading: false,
                        message: '',
                        delayToClose: 0,
                    });
                    const msg = code === UserErrorCodes.ALREADY_INVITED
                        ? UserErrorMessages[code]
                        : 'Something went wrong, please try again!';
                    showSnackbar(msg, 'error');
                },
            });
        }
    });
    const filtedRoles = (roles === null || roles === void 0 ? void 0 : roles.filter((role) => ![3, 6].includes(role.id))) || [];
    const filteredAgentData = useMemo(() => {
        return ((agentData === null || agentData === void 0 ? void 0 : agentData.filter((agent) => {
            const agentName = Formatter.contact(agent, {
                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
            });
            return agentName.toLowerCase().includes(searchAgent.toLowerCase());
        })) || []);
    }, [agentData, searchAgent, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    return (_jsx(Modal, { open: open, onClose: handleClose, children: _jsxs(Box, { sx: style, children: [_jsx(Typography, { variant: "h6", component: "h2", children: "Invite user" }), _jsxs(Box, { sx: { mt: 2 }, children: [_jsxs(FormControl, { fullWidth: true, sx: { mb: 2 }, children: [_jsx(EnhancedSelect, { options: filtedRoles, labelKey: "name", label: "Role", value: filtedRoles.find((role) => role.id === selectedRoleId), onChange: (v) => {
                                        setValue('roleId', v.id);
                                    } }), _jsx(FormHelperText, { error: Boolean(errors.roleId), children: (_a = errors.roleId) === null || _a === void 0 ? void 0 : _a.message })] }), _jsxs(FormControl, { fullWidth: true, sx: { mb: 2, display: displayAgent ? 'block' : 'none' }, children: [_jsx(EnhancedSelect, { sx: { width: '100%' }, enableSearch: true, searchKeyword: searchAgent, onSearch: setSearchAgent, options: filteredAgentData, label: "Agent", renderLabel: (input) => {
                                        const agent = filteredAgentData.find((agent) => agent.id === (input === null || input === void 0 ? void 0 : input.key));
                                        return (_jsx(Typography, { sx: Object.assign(Object.assign({}, input === null || input === void 0 ? void 0 : input.sx), { pl: 2 }), children: Formatter.contact(agent, {
                                                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                            }) }));
                                    }, value: filteredAgentData.find((item) => item.id === selectedAgentId), onChange: (v) => {
                                        setValue('agentId', v.id, { shouldValidate: true });
                                    } }), _jsx(FormHelperText, { error: Boolean(errors.agentId), children: (_b = errors.agentId) === null || _b === void 0 ? void 0 : _b.message })] }), _jsx(TextField, Object.assign({ label: "Email" }, register('email'), { variant: "outlined", fullWidth: true, helperText: errors.email ? errors.email.message : '', error: Boolean(errors.email) }))] }), _jsxs(Box, { sx: { display: 'flex', justifyContent: 'flex-end', mt: 2 }, children: [_jsx(Button, { onClick: handleClose, sx: { mr: 1 }, children: "Cancel" }), _jsx(Button, { variant: "contained", onClick: handleSubmit(handleInvite), children: "Invite user" })] })] }) }));
};
export default InviteUserForm;
