var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { MaterialReactTable, MRT_TablePagination, MRT_ToggleDensePaddingButton, MRT_ToggleFullScreenButton, } from 'material-react-table';
import { LoadingButton } from '@mui/lab';
import useSnackbar from '@/contexts/useSnackbar';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import { useExistingCustomers } from '../hooks/useExistingCustomers';
import { useLinkExistingCustomersByNames } from '../hooks/useLinkExistingCustomersByNames';
import { usePoliciesWithoutCustomerIds } from '@/components/PolicyDataView/apis';
import { useCreateCustomersFromPolicies } from '../apis';
import { useDeduplication } from '../hooks/useDeduplication';
import { DEDUPE_OPTIONS, DedupeValue } from '../constants';
import { useNewCustomerColumns } from '../hooks/useNewCustomerColumns';
import { useMapTypeAndSplitName } from '../hooks/useMapTypeAndSplitName';
import { useUpdateGroupIds } from '../hooks/useUpdateGroupIds';
export const NewCustomersTab = ({ onClose }) => {
    const [customerNames, setCustomerNames] = useState({});
    const [groupIds, setGroupIds] = useState({});
    const [dedupeBy, setDedupeBy] = useState(DedupeValue.Name);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [rowSelection, setRowSelection] = useState({});
    const mutation = useCreateCustomersFromPolicies();
    const { data: { total, data: policies } = {}, isLoading, isFetching, refetch, } = usePoliciesWithoutCustomerIds({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
    });
    const { dedupedList } = useDeduplication({
        data: policies || [],
        dedupeBy,
    });
    // Map customer type
    const { setCustomersType, data: mappedTypeAndSplitNameList } = useMapTypeAndSplitName({
        data: dedupedList,
        customerNames,
    });
    // Get existing customers by first and last name or company name
    const { existingCustomers = [] } = useExistingCustomers(policies || []);
    // Link existing customers with the same name
    const { data: mappedExistingCustomersList } = useLinkExistingCustomersByNames({
        data: mappedTypeAndSplitNameList || [],
        existingCustomers,
    });
    const { showSnackbar } = useSnackbar();
    const { columns } = useNewCustomerColumns({
        setCustomersType,
        setGroupIds,
        customerNames,
        setCustomerNames,
        dedupeBy,
    });
    const onSubmit = () => {
        const selectedKeys = Object.keys(rowSelection);
        const submitData = updatedGroupIdData
            .filter((item) => selectedKeys.includes(item.clientId))
            .map((item) => {
            const { clientId: __, dedupe_group_ids: _, dedupe_customers_names: ___, policies_ids: ____ } = item, rest = __rest(item, ["clientId", "dedupe_group_ids", "dedupe_customers_names", "policies_ids"]);
            return rest;
        });
        mutation.mutate(submitData, {
            onSuccess: () => {
                showSnackbar('Added customers', 'success');
                setRowSelection({});
                refetch();
            },
            onError: () => {
                showSnackbar('Something went wrong, please try again!', 'error');
            },
        });
    };
    const { data: updatedGroupIdData } = useUpdateGroupIds({
        groupIds,
        data: mappedExistingCustomersList,
    });
    useEffect(() => {
        setRowSelection({});
    }, [pagination]);
    return (_jsx(MaterialReactTable, { columns: columns, data: updatedGroupIdData, enableFilters: false, enableSorting: false, enableRowSelection: true, enableStickyHeader: true, enableStickyFooter: true, enableRowVirtualization: true, getRowId: (v) => v.clientId, initialState: {
            density: 'compact',
        }, state: {
            isLoading: isLoading || isFetching,
            pagination,
            rowSelection,
            columnVisibility: {
                dedupe_customers_names: !(dedupeBy === DedupeValue.Name),
            },
        }, onPaginationChange: setPagination, onRowSelectionChange: setRowSelection, manualPagination: true, rowCount: total, muiTablePaperProps: {
            elevation: 0,
            sx: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100%',
            },
        }, muiPaginationProps: {
            rowsPerPageOptions: [50, 250, 500, 1000],
        }, renderTopToolbar: ({ table }) => {
            return (_jsxs(Box, { sx: {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    mb: 1,
                }, children: [_jsx(EnhancedSelect, { sx: { mt: 1 }, label: "Dedupe by", options: DEDUPE_OPTIONS, value: DEDUPE_OPTIONS.find((option) => option.id === dedupeBy), onChange: (v) => setDedupeBy(v.id) }), _jsx(MRT_ToggleDensePaddingButton, { table: table }), _jsx(MRT_ToggleFullScreenButton, { table: table })] }));
        }, renderBottomToolbar: ({ table }) => {
            return (_jsxs(Box, { sx: {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    mb: 1,
                }, children: [_jsx(MRT_TablePagination, { table: table }), _jsxs(Box, { children: [_jsx(Button, { sx: { mr: 1 }, autoFocus: true, onClick: onClose, children: "Cancel" }), _jsx(LoadingButton, { onClick: onSubmit, loading: mutation.isPending, variant: "contained", disabled: !Object.values(rowSelection).length, children: "Submit" })] })] }));
        } }));
};
