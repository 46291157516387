var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography, } from '@mui/material';
import { green, red } from '@mui/material/colors';
import * as Sentry from '@sentry/react';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';
import { sha256 } from 'crypto-hash';
import { nanoid } from 'nanoid';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState, } from 'react';
import { useBeforeUnload } from 'react-use';
import { useOriginalFile } from 'store/excelStore';
import FilePreview from '@/common/preview';
import { XLS_CSV_TYPES } from '@/common/preview/model';
import DuplicateFilesDetector from '@/components/UploadModal/duplicateFilesDetector';
import useSnackbar from '@/contexts/useSnackbar';
import { useUploadStorageFileV2 } from '@/contexts/useUploadStorageFileV2';
import API from '@/services/API';
import { normalizeCurrency } from '@/services/DataTransformation/normalizer';
import Spreadsheet from '@/services/Spreadsheet';
import { readFile } from '@/services/helpers';
import { useAccountStore } from '@/store';
import useUploadStore from '@/store/uploadStore';
const PreviewUploads = forwardRef(({ formModel, onFileUploadSuccess, onFinishUpload }, ref) => {
    const [currentPreviewFile, setCurrentPreviewFile] = useState();
    const [selectOptions, setSelectOptions] = useState([]);
    const [spreadsheet, setSpreadsheet] = useState();
    const [fileHashes, setFileHashes] = useState();
    const [hasDuplicateFile, setHasDuplicateFile] = useState(false);
    const originalFile = useOriginalFile();
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();
    const documentsPoster = API.getMutation('documents', 'POST');
    const documentProfilePoster = API.getMutation('document_profiles', 'POST');
    const { uploadFile } = useUploadStorageFileV2();
    const uploadProgresses = useUploadStore((s) => s.uploadProgresses);
    const setUploadProgress = useUploadStore((s) => s.setUploadProgress);
    const resetProgresses = useUploadStore((s) => s.resetProgresses);
    const uploading = Object.values(uploadProgresses).some((item) => item === 'uploading');
    useEffect(() => {
        const fetchFileHashes = () => __awaiter(void 0, void 0, void 0, function* () {
            const hashes = {};
            yield Promise.all(selectOptions.map((option) => __awaiter(void 0, void 0, void 0, function* () {
                const file = option.data;
                const fileName = file.name;
                const fileContent = yield readFile(file);
                const fileHash = yield sha256(fileContent);
                hashes[fileName] = fileHash;
            })));
            setFileHashes(hashes);
        });
        fetchFileHashes();
    }, [selectOptions]);
    useEffect(() => {
        const values = Object.values(uploadProgresses);
        const finished = values.length && !uploading;
        console.log('values', values);
        if (finished) {
            console.log('finishing');
            onFinishUpload(values.some((item) => item === 'failed'));
            resetProgresses();
        }
    }, [onFinishUpload, uploadProgresses, uploading]);
    useBeforeUnload(uploading, 'You have unsaved files, are you sure you want to leave?');
    useBeforeUnloadPage(uploading, 'You have unsaved files, are you sure you want to leave?');
    const loadFilePath = (file) => {
        if (!(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) || !file) {
            return '';
        }
        const filename = `${nanoid()}-${file.name}`;
        const accountId = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId;
        let filePath = 'uploads/';
        if (accountId) {
            filePath += `${accountId}/`;
        }
        filePath += filename;
        return filePath;
    };
    const loadUploadBody = () => __awaiter(void 0, void 0, void 0, function* () {
        // Loop selectOption
        const promises = selectOptions.map((option) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g;
            const file = option.data;
            const filePath = loadFilePath(file);
            const fileContent = yield readFile(file);
            const fileHash = yield sha256(fileContent);
            const bankTotalKey = (file === null || file === void 0 ? void 0 : file.path) || '';
            const bankTotalAmount = (_a = formModel.bank_total_amounts) === null || _a === void 0 ? void 0 : _a[bankTotalKey];
            const checkDate = (_b = formModel.check_dates) === null || _b === void 0 ? void 0 : _b[bankTotalKey];
            const depositDate = (_c = formModel.deposit_dates) === null || _c === void 0 ? void 0 : _c[bankTotalKey];
            const company_str_id = ((_e = (_d = formModel.companies) === null || _d === void 0 ? void 0 : _d[file.name]) === null || _e === void 0 ? void 0 : _e.value) || ((_f = formModel.companies) === null || _f === void 0 ? void 0 : _f.value);
            const document_type = ((_g = formModel.document_type) === null || _g === void 0 ? void 0 : _g[file.name]) || formModel.document_type;
            const body = {
                filename: file.name,
                file_path: filePath,
                type: document_type,
                method: '',
                processor: '',
                file_hash: fileHash,
                file,
                status: 'new',
                company_str_id: company_str_id,
                tag: formModel.tag,
                docType: option.docType,
                bank_total_amount: bankTotalAmount
                    ? normalizeCurrency(bankTotalAmount)
                    : null,
                check_date: checkDate,
                deposit_date: depositDate,
            };
            return body;
        }));
        const bodys = yield Promise.all(promises);
        return bodys;
    });
    const getFailedFileNames = () => {
        const fileNames = [];
        for (const key in uploadProgresses) {
            if (uploadProgresses[key] === 'failed')
                fileNames.push(key);
        }
        return fileNames;
    };
    const postUploadsInfo = (params) => __awaiter(void 0, void 0, void 0, function* () {
        let bodys = yield loadUploadBody();
        if (params === null || params === void 0 ? void 0 : params.tryAgain) {
            const failedFileNames = getFailedFileNames();
            bodys = bodys.filter((item) => failedFileNames.includes(item.filename));
        }
        bodys.forEach((_a) => __awaiter(void 0, void 0, void 0, function* () {
            var { file, docType } = _a, rest = __rest(_a, ["file", "docType"]);
            try {
                const docPoster = Object.assign({}, rest);
                const uploadInfo = yield documentsPoster.mutateAsync(docPoster);
                // Create/update document_profile record
                const docTypeParams = {
                    carrier_name: uploadInfo.company_str_id,
                    paying_entity: uploadInfo.company_str_id,
                    owner: '',
                    field_mapping: '',
                    status: 'draft',
                    file_link: [rest.file_path],
                    notes: '',
                    document_str_ids: [uploadInfo.str_id],
                    mappings_str_ids: uploadInfo.mapping ? [uploadInfo.mapping] : [],
                };
                // TODO - refactor:this step is optional
                // and should be done on Server side
                documentProfilePoster
                    .mutateAsync(docTypeParams)
                    .then((docProfile) => {
                    onFileUploadSuccess({
                        document: uploadInfo,
                        profile: docProfile,
                    });
                })
                    .catch((ex) => {
                    Sentry.captureException(ex);
                });
                setUploadProgress({ filename: file.name, state: 'uploading' });
                yield uploadFile({
                    file,
                    action: 'write',
                    endpoint: 'documents',
                    endpoint_str_id: uploadInfo.str_id,
                    file_preview_type: uploadInfo.override_file_path
                        ? 'override'
                        : 'original',
                });
                setUploadProgress({ filename: file.name, state: 'done' });
            }
            catch (error) {
                setUploadProgress({ filename: file.name, state: 'failed' });
                const errMsg = error.message || error;
                Sentry.captureException(error);
                showSnackbar(`Failed to create document record: ${errMsg}`, 'error');
            }
        }));
    });
    const doChange = useCallback((file) => __awaiter(void 0, void 0, void 0, function* () {
        if (!file) {
            return;
        }
        setCurrentPreviewFile(file);
    }), []);
    const onChange = (e) => __awaiter(void 0, void 0, void 0, function* () {
        const val = e.target.value;
        if (!val) {
            return;
        }
        const file = selectOptions.find((item) => item.value === val).data;
        doChange(file);
    });
    useEffect(() => {
        // Excel, csv need to load the raw data
        const setExcelData = () => __awaiter(void 0, void 0, void 0, function* () {
            if (currentPreviewFile &&
                XLS_CSV_TYPES.includes(currentPreviewFile.type)) {
                const res = (yield Spreadsheet.loadSpreadsheet(currentPreviewFile));
                setSpreadsheet(res);
            }
        });
        setExcelData();
    }, [currentPreviewFile]);
    useEffect(() => {
        if (originalFile) {
            setSelectOptions((prev) => {
                const newOptions = originalFile.map((file) => ({
                    label: file.name,
                    value: file.name,
                    data: file,
                    docType: 'original',
                }));
                const uniqueOptions = newOptions.filter((option) => !prev.some((item) => item.label === option.label));
                return [...prev, ...uniqueOptions];
            });
        }
    }, [originalFile]);
    useEffect(() => {
        const selectOne = () => __awaiter(void 0, void 0, void 0, function* () {
            if (selectOptions.length > 0) {
                yield doChange(selectOptions[0].data);
            }
        });
        selectOne();
    }, [doChange, originalFile, selectOptions, selectOptions.length]);
    useImperativeHandle(ref, () => ({
        submit: postUploadsInfo,
    }));
    const renderUploadProgressIcon = (filename) => {
        switch (uploadProgresses[filename]) {
            case 'done':
                return _jsx(CheckIcon, { sx: { marginLeft: '10px', color: green['500'] } });
            case 'failed':
                return (_jsx(ErrorOutlineIcon, { sx: { marginLeft: '10px', color: red['500'] } }));
            case 'uploading':
                return _jsx(CircularProgress, { size: "20px", sx: { marginLeft: '10px' } });
            default:
                return null;
        }
    };
    const renderDuplicateFilesIcon = (filename) => {
        if (fileHashes &&
            Object.prototype.hasOwnProperty.call(fileHashes, filename)) {
            return (_jsx(DuplicateFilesDetector, { hash: fileHashes[filename], hasDuplicateFile: hasDuplicateFile, setHasDuplicateFile: setHasDuplicateFile }));
        }
        return null;
    };
    return (_jsxs(Box, { sx: {
            width: '100%',
            height: '100%',
            maxHeight: 'calc(100vh - 240px)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        }, children: [_jsx("section", { children: currentPreviewFile && (_jsxs(FormControl, { fullWidth: true, sx: { mt: 1 }, children: [_jsx(InputLabel, { children: "File" }), _jsx(Select, { fullWidth: true, value: currentPreviewFile.name, onChange: onChange, sx: { marginBottom: 2 }, label: "File", renderValue: (v) => {
                                return (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Typography, { sx: { flexGrow: 1 }, children: v }), renderDuplicateFilesIcon(v), renderUploadProgressIcon(v)] }));
                            }, children: selectOptions.map((item) => (_jsxs(MenuItem, { sx: { alignItems: 'center' }, value: item.value, children: [_jsx(Typography, { sx: { flexGrow: 1 }, children: item.label }), renderDuplicateFilesIcon(item.value), renderUploadProgressIcon(item.value)] }, item.value))) })] })) }), hasDuplicateFile && (_jsx(Typography, { variant: "body2", color: "error", sx: {
                    marginTop: '-10px',
                    marginLeft: 'auto',
                    marginRight: '4px',
                    textAlign: 'right',
                }, children: "This file is a duplicate of an existing document. Click above to view existing duplicate files." })), _jsx(FilePreview, { previewFile: currentPreviewFile, previewWidth: window.innerWidth * 0.8, 
                // @ts-ignore
                spreadsheet: spreadsheet, 
                // @ts-ignore
                setSpreadsheet: setSpreadsheet })] }));
});
PreviewUploads.displayName = 'PreviewUploads';
export default PreviewUploads;
