import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { RotateRight } from '@mui/icons-material';
import ExcelPreview from '@/common/preview/ExcelPreview';
import PDFPreview from '@/common/preview/PdfPreview';
import IMGPreview from '@/common/preview/imgPreview';
import TextPreview from '@/common/preview/TextPreview';
import HTMLPreview from '@/common/preview/HTMLPreview';
import { IMG_TYPES, PDF_TYPES, XLS_CSV_TYPES, Plain_TEXT_TYPES, HTML_TYPES, } from '@/common/preview/model';
import useSnackbar from '@/contexts/useSnackbar';
import { DocumentPreviewTypes } from '@/types';
const FilePreview = ({ spreadsheet, setSpreadsheet, previewFile, previewWidth, sheet, }) => {
    const [preview, setPreview] = useState(null);
    const [rotate, setRotate] = useState(0);
    const { showSnackbar } = useSnackbar();
    const handleRotate = () => setRotate((prev) => (prev + 90) % 360);
    useEffect(() => {
        var _a;
        if (!previewFile)
            return;
        const fileTypeMap = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, Object.fromEntries(XLS_CSV_TYPES.map((type) => [type, DocumentPreviewTypes.EXCEL]))), Object.fromEntries(PDF_TYPES.map((type) => [type, DocumentPreviewTypes.PDF]))), Object.fromEntries(IMG_TYPES.map((type) => [type, DocumentPreviewTypes.IMG]))), Object.fromEntries(Plain_TEXT_TYPES.map((type) => [type, DocumentPreviewTypes.TEXT]))), Object.fromEntries(HTML_TYPES.map((type) => [type, DocumentPreviewTypes.HTML])));
        const previewType = (_a = fileTypeMap[previewFile.type]) !== null && _a !== void 0 ? _a : null;
        setPreview(previewType);
        if (!previewType)
            showSnackbar('Unsupported file type', 'error');
    }, [previewFile, showSnackbar]);
    return (_jsxs(Box, { sx: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            position: 'relative',
        }, children: [preview === DocumentPreviewTypes.EXCEL && (_jsx(ExcelPreview, { spreadsheet: spreadsheet, setSpreadsheet: setSpreadsheet, sheet: sheet })), preview === DocumentPreviewTypes.PDF && (_jsxs(_Fragment, { children: [_jsx(RotateRight, { onClick: handleRotate, color: "action", sx: {
                            position: 'absolute',
                            right: 40,
                            top: 5,
                            zIndex: 100,
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'rotate(90deg)',
                                color: '#2196f3',
                                transition: 'all 0.1s ease-in-out',
                            },
                        } }), _jsx(PDFPreview, { file: previewFile, previewWidth: previewWidth, rotate: rotate })] })), preview === DocumentPreviewTypes.IMG && (_jsx(IMGPreview, { file: previewFile })), preview === DocumentPreviewTypes.TEXT && (_jsx(TextPreview, { file: previewFile })), preview === DocumentPreviewTypes.HTML && (_jsx(HTMLPreview, { file: previewFile }))] }));
};
export default FilePreview;
