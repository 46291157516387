import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Popover from '@mui/material/Popover';
import { useMemo, useState } from 'react';
import { Box, FormControl, InputAdornment, InputLabel, TextField, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SearchBox } from './SearchBox';
import List from './ListAndRow';
import { AllLabel, AllValue } from '../constants';
import { SelectSearchResultToggle } from './SelectSearchResultToggle';
export const BaseSelect = (props) => {
    const { options, onChange, value, multiple, disabled = false, label, sx, enableSearch, renderLabel, disableAllOption = false, searchKeyword, onSearch, tokenizeSearch = true, listContainerSx, sortLabel = true, } = props;
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const _searchValue = onSearch ? searchKeyword : search;
    const open = Boolean(anchorEl);
    const isShrink = open || !!(multiple ? value === null || value === void 0 ? void 0 : value.length : value);
    const selectedValues = useMemo(() => {
        return Array.isArray(value)
            ? value.reduce((prev, cur) => {
                prev[cur.value] = true;
                return prev;
            }, {})
            : { [(value === null || value === void 0 ? void 0 : value.value) || '']: true };
    }, [value]);
    const isAllSelected = Array.isArray(value) && value.length > 0 && value.length === options.length;
    const _onChange = (item) => {
        // Handle All select
        if (item.value === AllValue) {
            if (isAllSelected)
                onChange([]);
            else
                onChange(options);
            return;
        }
        if (multiple) {
            const _value = Array.isArray(value) ? [...value] : [];
            const index = _value.findIndex((i) => i.value === item.value);
            if (index !== -1) {
                _value.splice(index, 1);
            }
            else {
                _value.push(item);
            }
            onChange(_value);
        }
        else {
            onChange(item);
        }
        if (!multiple)
            handleClose();
    };
    const filteredList = useMemo(() => {
        let _list = [...options];
        const doSearch = () => {
            if (!search)
                return;
            if (tokenizeSearch) {
                const tokens = search.toLowerCase().split(/[,\s]+/);
                _list = options.filter((item) => !!tokens.some((token) => { var _a; return (_a = item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(token); }));
            }
            else {
                _list = options.filter((item) => { var _a; return (_a = item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase()); });
            }
        };
        doSearch();
        return _list;
    }, [options, search, tokenizeSearch]);
    const sortedList = useMemo(() => {
        const _sorted = [...filteredList];
        if (sortLabel) {
            return _sorted.sort((a, b) => {
                if (typeof a.label === 'string' && typeof b.label === 'string')
                    return a.label.localeCompare(b.label || '');
                return 0;
            });
        }
        return _sorted;
    }, [filteredList, sortLabel]);
    const listWithAllOption = useMemo(() => {
        if (multiple && !disableAllOption && sortedList.length && !_searchValue) {
            sortedList.unshift({ label: AllLabel, value: AllValue });
        }
        return sortedList;
    }, [_searchValue, disableAllOption, multiple, sortedList]);
    const onTargetSelect = (item) => {
        if (multiple)
            onChange([item]);
    };
    const itemData = {
        options: listWithAllOption,
        onChange: _onChange,
        selectedValues,
        multiple,
        isAllSelected,
        onTargetSelect,
        renderLabel,
        disableAllOption,
    };
    const handleClick = (event) => {
        if (disabled)
            return;
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const displayValue = useMemo(() => {
        if (multiple) {
            const valuesLength = value === null || value === void 0 ? void 0 : value.length;
            if (valuesLength === options.length && options.length > 0)
                return `All (${valuesLength})`;
            else if (valuesLength)
                return `${valuesLength} selected`;
            return '';
        }
        return value === null || value === void 0 ? void 0 : value.label;
    }, [multiple, options.length, value]);
    const _onSearch = (v) => {
        if (onSearch)
            onSearch(v);
        else
            setSearch(v);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(FormControl, { onClick: handleClick, sx: Object.assign({ minWidth: 160, mr: 1 }, sx), children: [!isShrink && (_jsx(InputLabel, { sx: { marginTop: isShrink ? 0 : '-3px' }, shrink: isShrink, children: label })), _jsx(TextField, { label: isShrink ? label : null, sx: {
                            '& .MuiInputBase-root': {
                                height: '35px',
                                paddingRight: 0,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            },
                            '& .MuiInputBase-input': {
                                opacity: 0,
                                width: 0,
                                paddingRight: 0,
                                height: '100%',
                                py: 0,
                            },
                        }, InputProps: {
                            startAdornment: (_jsx(Box, { sx: {
                                    width: '100%',
                                    cursor: 'pointer',
                                    lineHeight: '40px',
                                    zIndex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    overflow: 'hidden',
                                }, children: _jsx(Box, { sx: {
                                        width: '100%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }, children: displayValue }) })),
                            endAdornment: (_jsx(InputAdornment, { sx: {
                                    cursor: 'pointer',
                                    flexShrink: 0,
                                    marginLeft: 1,
                                    zIndex: 1,
                                }, position: "start", children: _jsx(ExpandMoreIcon, { sx: { transform: open ? 'rotate(180deg)' : 'rotate(0)' } }) })),
                        } })] }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, children: _jsxs(Box, { sx: Object.assign({ minWidth: 300, width: '100%' }, listContainerSx), children: [enableSearch && (_jsx(SearchBox, { search: _searchValue, onChange: _onSearch })), _jsx(SelectSearchResultToggle, Object.assign({}, props, { searchKeyword: _searchValue, filteredList: listWithAllOption })), _jsx(List, { itemData: itemData })] }) })] }));
};
