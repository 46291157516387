import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DateItem } from 'common/documents/documents.types';

const Prefix = `${process.env.REACT_APP_API}/api`;

export const API_PATHS = {
  group: `${Prefix}/documents/groups`,
};

export const useGroupData = () => {
  return useQuery<DateItem[]>({
    queryKey: [API_PATHS.group],
    queryFn: async () => {
      return axios.get(API_PATHS.group).then((res) => res.data);
    },
  });
};
