import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, FormHelperText, Typography, IconButton } from '@mui/material';
import { AttachFile as AttachFileIcon, AddCircle as AddCircleIcon, } from '@mui/icons-material';
import PropTypes from 'prop-types';
// Excel, pdf, png, jpg, jpeg, csv, xlsx, txt, html
const acceptMIME = {
    'application/vnd.ms-excel': ['.xls'],
    'application/pdf': ['.pdf'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'text/csv': ['.csv'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
    ],
    'text/plain': ['.txt'],
    'text/html': ['.html'],
};
// Png, jpg, jpeg
const acceptImgMIME = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
};
const FactoryFileUpload = ({ label, required, onChange, error, curFile, addFileMode = false, imageOnly = false, multiple = false, buttonStyle = false, showFileInfo = true, buttonComp = _jsx(_Fragment, {}), }) => {
    const [files, setFiles] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
        onChange(acceptedFiles);
    }, []);
    useEffect(() => {
        if (curFile) {
            setFiles(curFile);
        }
    }, [curFile]);
    let useDropzoneProps = {};
    if (multiple) {
        useDropzoneProps = {
            accept: imageOnly ? acceptImgMIME : acceptMIME,
            onDrop,
        };
    }
    else {
        useDropzoneProps = {
            accept: imageOnly ? acceptImgMIME : acceptMIME,
            onDrop,
            maxFiles: 1,
        };
    }
    const { getRootProps, getInputProps } = useDropzone(useDropzoneProps);
    const wrapperStyle = {
        height: addFileMode ? '50px' : '90px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        justifyContent: 'center',
        flexDirection: 'column',
    };
    return (_jsx(_Fragment, { children: !buttonStyle ? (_jsxs(Box, { children: [label && _jsx(Typography, { sx: { my: 1 }, children: label }), _jsxs("div", Object.assign({}, getRootProps(), { children: [_jsx("input", Object.assign({ required: required }, getInputProps())), _jsx("div", { style: wrapperStyle, className: "cursor-pointer border-dashed border border-gray-300 hover:border-blue-500 transition-colors", children: addFileMode ? (_jsx(IconButton, { "aria-label": "upload file", sx: {
                                    color: 'gray',
                                    '&:hover': {
                                        color: 'darkgray',
                                        transition: 'transform 0.3s ease, color 0.3s ease',
                                    },
                                }, children: _jsx(AddCircleIcon, { fontSize: "large" }) })) : (_jsxs(_Fragment, { children: [_jsx("p", { className: "m-0 mx-2", children: "Drag and drop file here or click to browse files" }), imageOnly ? (_jsx("p", { className: "m-0 text-gray-400", children: "(.png, .jpg, .jpeg)" })) : (_jsx("p", { className: "m-0 text-gray-400", children: "(.csv, .xls, .pdf, .png, .jpg .html)" }))] })) })] })), showFileInfo && (_jsx("aside", { children: _jsx("ul", { className: "m-0 p-0 flex flex-col", children: files &&
                            files.map((f) => (_jsxs("li", { className: "list-none py-1 align-middle", children: [_jsx(AttachFileIcon, { className: "text-base mr-1" }), f.path, " - ", (f.size / 1024).toFixed(2), " KB"] }, f.path))) }) })), _jsx("div", { children: required && error && (_jsx(FormHelperText, { error: error, children: "Required" })) })] })) : (_jsxs(Box, Object.assign({}, getRootProps(), { children: [_jsx("input", Object.assign({ required: required }, getInputProps())), buttonComp] }))) }));
};
FactoryFileUpload.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    curFile: PropTypes.object,
    imageOnly: PropTypes.bool,
    multiple: PropTypes.bool,
    buttonStyle: PropTypes.bool,
    buttonComp: PropTypes.element,
    showFileInfo: PropTypes.bool,
};
export default FactoryFileUpload;
