var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState, } from 'react';
import { nanoid } from 'nanoid';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { LoadingContext } from 'contexts/LoadingContext';
import useWindowSize from 'contexts/useWindowSize';
import { useBeforeUnload } from 'react-use';
import useExtraction from '@/contexts/useExtraction';
import ProcessPreview from '@/components/UploadModal/ProcessPreview';
import CommissionMapper from '@/components/UploadModal/processFlow/CommissionMapper';
import useCommonData from '@/components/UploadModal/processFlow/hoc/useCommonData';
import useFileToData from '@/components/UploadModal/processFlow/hoc/useFileToData';
import { DocumentTypeE, ProcessMethodE, } from '@/components/UploadModal/processFlow/process';
import useBeforeUnloadPage from '@/contexts/useBeforeunloadPage';
import API from '@/services/API';
import useAutoPolling from '@/contexts/useAutoPolling';
import StickyTips from './StickyTips';
import { tool } from '@/common/tools';
import { ProcessSuccessStatus } from './config';
const ProcessUploads = ({ rowData, mpData, setRowData, actionCount, setActionCount, timerCountRef, feedback, }, ref) => {
    const [processForm, setProcessForm] = useState({
        mapping: 0,
        newMappingCarrier: null,
        newMappingName: '',
        processor: '',
        prompt: '',
        promptText: '',
        method: '',
        fileName: '',
        fileType: DocumentTypeE.Statement,
        selectedSheet: '',
    });
    const tipRef = useRef(null);
    const [fileType, setFileType] = useState(DocumentTypeE.Statement);
    const [spreadsheet, setSpreadsheet] = useState();
    const [selectedSheet, setSelectedSheet] = useState('');
    const [startPolling, setStartPolling] = useState(false);
    const [pollingParams, setPollingParams] = useState({
        jobId: '',
        uploadId: rowData.id,
    });
    const [errors, setErrors] = useState({});
    const [processActionMap, setProcessActionMap] = useState({});
    const [processFormatData, setProcessFormatData] = useState({
        mappingOptions: {},
        cmsTotal: '',
        data: [],
        rowData: [],
    });
    const { loadingConfig, setLoadingConfig } = useContext(LoadingContext);
    const { data: prompts } = API.getBasicQuery('prompts');
    useBeforeUnload(loadingConfig.loading, 'You have unsaved changes, are you sure you want to leave?');
    useBeforeUnloadPage(loadingConfig.loading, 'You have unsaved changes, are you sure you want to leave?');
    const addActionCount = useCallback((type) => {
        setActionCount(actionCount + 1);
        if (!type)
            return;
        setProcessActionMap((prev) => {
            var _a;
            return Object.assign(Object.assign({}, prev), { [type]: ((_a = prev[type]) !== null && _a !== void 0 ? _a : 0) + 1 });
        });
    }, [actionCount]);
    useEffect(() => {
        setLoadingConfig({
            loading: startPolling,
            message: 'Processing document...',
            allowClose: true,
        });
    }, [startPolling]);
    const pollingFetchExtractTable = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const extractionOption = ((_a = rowData.method) === null || _a === void 0 ? void 0 : _a.split('::')) || [];
        const extractionId = extractionOption.length > 1 ? extractionOption.pop() : '';
        const extractionStrId = `jobId=${pollingParams.jobId}&uploadId=${pollingParams.uploadId}&extractionId=${extractionId}`;
        try {
            const url = `${process.env.REACT_APP_API}/api/documents/extractData?${extractionStrId}`;
            const _res = yield fetch(url, {
                method: 'GET',
                headers: yield API.getHeaders(),
            });
            const res = yield _res.json();
            if (res.error || res.type === 'expired') {
                setErrors(Object.assign(Object.assign({}, errors), { pollingRow: res.message || res.error }));
                setStartPolling(false);
                Sentry.captureException(res.error || res.message);
                return null;
            }
            return res;
        }
        catch (e) {
            setErrors(Object.assign(Object.assign({}, errors), { pollingRow: `Error uploading file (${e.message})` }));
            setStartPolling(false);
            Sentry.captureException(e);
            return null;
        }
    });
    const checkDataCondition = (data) => {
        const successList = ProcessSuccessStatus;
        if (successList.includes(data === null || data === void 0 ? void 0 : data.JobStatus)) {
            setErrors(Object.assign(Object.assign({}, errors), { pollingRow: '' }));
            return true;
        }
        return false;
    };
    const { data: extractTableData } = useAutoPolling(pollingFetchExtractTable, checkDataCondition, 8000, startPolling);
    const { width: winWidth } = useWindowSize();
    const [previewWidth, setPreviewWidth] = useState(winWidth * 0.25);
    const { fileData, file, setFile } = useFileToData(rowData, processForm);
    const { fields } = useCommonData(fileType, file);
    // Extract table hook
    const { setExtraction, setExtractionMethod, extractTableJson, extractTableRaw, } = useExtraction();
    const mappingsPoster = API.getMutation('mappings', 'POST');
    const documentsPatcher = API.getMutation('documents', 'PATCH');
    const statementDataPoster = API.getMutation('statement_data', 'POST');
    const reportDataPoster = API.getMutation('report_data', 'POST');
    const { data: extractionData = [], isLoading: extractionLoading } = API.getBasicQuery('extractions', `document_id=${rowData.id}`, !!rowData.id);
    const setDataToSpreetSheet = (data) => {
        if (data && data.length > 0) {
            const newSpreadsheet = {
                getSheets: () => data.map((e, i) => `${i}`),
                getJson: (i) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data[i]) !== null && _a !== void 0 ? _a : []; },
            };
            setSpreadsheet(newSpreadsheet);
        }
    };
    /** Polling result */
    useEffect(() => {
        const doAction = () => __awaiter(void 0, void 0, void 0, function* () {
            if (extractTableData && !extractionLoading) {
                setStartPolling(false);
                setExtractionMethod('extractTable');
                setExtraction(JSON.stringify(extractTableData));
                setRowData(() => {
                    var _a, _b, _c, _d;
                    const method = `extractTable::${(_b = (_a = extractionData[0]) === null || _a === void 0 ? void 0 : _a.str_id) !== null && _b !== void 0 ? _b : ''}::${(_d = (_c = extractionData[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : ''}`;
                    return Object.assign(Object.assign({}, rowData), { extractions: extractionData, method });
                });
                setErrors(Object.assign(Object.assign({}, errors), { dataRows: '', expiredRow: '', pollingRow: '' }));
            }
        });
        doAction();
    }, [extractTableData, extractionData, extractionLoading]);
    useEffect(() => {
        if (processForm.prompt && prompts) {
            const target = prompts.find((item) => item.str_id === processForm.prompt);
            if (target && target.prompt) {
                setProcessForm((prev) => {
                    return Object.assign(Object.assign({}, prev), { promptText: target.prompt });
                });
            }
        }
    }, [processForm.prompt, prompts]);
    useEffect(() => {
        if (!fileData) {
            return;
        }
        if (fileData.error) {
            setErrors(Object.assign(Object.assign({}, errors), { dataRows: fileData.error }));
        }
        else {
            const { data, type } = fileData;
            if (type === 'spreadsheet') {
                setSpreadsheet(data);
            }
            else if (type === 'documentAI') {
                setDataToSpreetSheet(data.table);
                setExtractionMethod('documentAI');
                setExtraction(data);
            }
            else if (type === 'extractTable') {
                // Check the document is processing or not
                const parseData = JSON.parse(data);
                if ((parseData === null || parseData === void 0 ? void 0 : parseData.JobStatus) === 'Processing') {
                    setErrors(Object.assign(Object.assign({}, errors), { pollingRow: 'This document is taking a while to process. You can continue to wait or leave this page and check back later. Document extractions usually finish within a couple of minutes.' }));
                    setPollingParams({
                        jobId: parseData.JobId,
                        uploadId: rowData.id,
                    });
                    setStartPolling(true);
                    return;
                }
                if ((parseData === null || parseData === void 0 ? void 0 : parseData.ProTip) || (parseData === null || parseData === void 0 ? void 0 : parseData.message)) {
                    setErrors(Object.assign(Object.assign({}, errors), { expiredRow: `${parseData.ProTip} (${parseData.message})` }));
                    return;
                }
                setExtractionMethod('extractTable');
                setExtraction(data);
                // Check processor
                const getExtraction = () => __awaiter(void 0, void 0, void 0, function* () {
                    setRowData(() => {
                        var _a, _b, _c, _d;
                        const method = `extractTable::${(_b = (_a = extractionData[0]) === null || _a === void 0 ? void 0 : _a.str_id) !== null && _b !== void 0 ? _b : ''}::${(_d = (_c = extractionData[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : ''}`;
                        return Object.assign(Object.assign({}, rowData), { extractions: extractionData, method });
                    });
                });
                if (rowData.method &&
                    !rowData.method.includes('::') &&
                    !extractionLoading) {
                    getExtraction();
                }
                setErrors(Object.assign(Object.assign({}, errors), { dataRows: '', expiredRow: '' }));
            }
            else if (type === 'adobeExtract') {
                setDataToSpreetSheet(data);
                setExtractionMethod('adobeExtract');
                setExtraction(data);
            }
            else if (type === 'nanonets') {
                setExtractionMethod('nanonets');
                setExtraction(data);
            }
            else if (type === 'htmlExtract') {
                setExtractionMethod('htmlExtract');
                setExtraction(data);
            }
            else if (type === 'gemini') {
                setProcessForm((pre) => {
                    return Object.assign(Object.assign({}, pre), { method: type });
                });
            }
        }
    }, [fileData, extractionLoading]);
    /**
     * Set extract table json to spreadsheet
     */
    useEffect(() => {
        if (extractTableJson) {
            const formatData = [JSON.parse(extractTableJson)];
            setDataToSpreetSheet(formatData);
        }
    }, [extractTableJson]);
    useEffect(() => {
        if (rowData.type) {
            setFileType(rowData.type);
        }
    }, [rowData]);
    useEffect(() => {
        var _a;
        // Filter the mapping value which it's name includes company name from the rowData
        const targetMapping = (_a = mpData.mappings) === null || _a === void 0 ? void 0 : _a.find((mapping) => {
            var _a;
            return ((_a = mapping.carrier) === null || _a === void 0 ? void 0 : _a.str_id) === rowData.company_str_id;
        });
        let _method = rowData.method;
        if (_method) {
            const isGemini = _method === null || _method === void 0 ? void 0 : _method.includes('gemini');
            const isExtractTable = _method === null || _method === void 0 ? void 0 : _method.includes('extractTable');
            const isMapping = _method === null || _method === void 0 ? void 0 : _method.includes('mapping');
            const isAdobePDFExtract = _method === null || _method === void 0 ? void 0 : _method.includes('adobeExtract');
            const isNanonets = _method === null || _method === void 0 ? void 0 : _method.includes('nanonets');
            const isHtmlExtract = _method === null || _method === void 0 ? void 0 : _method.includes('htmlExtract');
            if (isMapping)
                _method = ProcessMethodE.Mapping;
            if (isExtractTable || isAdobePDFExtract || isNanonets || isHtmlExtract)
                _method = ProcessMethodE.Processor;
            if (isGemini)
                _method = ProcessMethodE.Gemini;
        }
        setProcessForm((prev) => (Object.assign(Object.assign({}, prev), { fileName: (file === null || file === void 0 ? void 0 : file.name) || '', selectedSheet, mapping: targetMapping ? targetMapping.str_id : 0, newMappingName: prev.newMappingName ||
                (rowData.companies ? `${rowData.companies.company_name} mapping` : ''), method: _method, prompt: rowData.prompt, newMappingCarrier: rowData.companies ? rowData.companies : 0, fileType: fileType === DocumentTypeE.Statement
                ? 'Commission Statement'
                : 'Policy Report' })));
    }, [file, fileType, selectedSheet, mpData.mappings]);
    //* ****************************Upload mapping start************************************/
    const uploadMapping = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            // Upload
            if (file) {
                let mapping;
                if (processForm.mapping === 0) {
                    const newMapping = {
                        name: processForm.newMappingName,
                        type: fileType,
                        carrier_id: processForm.newMappingCarrier &&
                            typeof processForm.newMappingCarrier !== 'number'
                            ? (_a = processForm.newMappingCarrier) === null || _a === void 0 ? void 0 : _a.str_id
                            : processForm.newMappingCarrier,
                        mapping: processFormatData.mappingOptions,
                    };
                    const res = yield mappingsPoster.mutateAsync(newMapping);
                    if (res.error) {
                        setErrors(Object.assign(Object.assign({}, errors), { upload: `Error uploading mapping (${res.error})` }));
                        Sentry.captureException(res.error);
                        return null;
                    }
                    mapping = res.str_id;
                }
                else {
                    mapping = processForm.mapping;
                }
                return mapping;
            }
            return null;
        }
        catch (e) {
            setErrors(Object.assign(Object.assign({}, errors), { upload: `Error uploading mapping (${e.message})` }));
            Sentry.captureException(e);
            return null;
        }
    });
    const bulkUpload = (_a) => __awaiter(void 0, [_a], void 0, function* ({ mapping }) {
        var _b, _c, _d;
        try {
            const importId = nanoid();
            const mappingList = Object.keys(processFormatData.mappingOptions);
            const formatFields = [];
            mappingList.forEach((k) => {
                const targetField = fields[k];
                if ((targetField === null || targetField === void 0 ? void 0 : targetField.normalizer) &&
                    typeof targetField.normalizer === 'function') {
                    formatFields.push(k);
                }
            });
            const copyData = [...processFormatData.rowData];
            const normalizedData = copyData.map((datum) => {
                // Format value
                Object.keys(datum).forEach((k) => {
                    if (formatFields === null || formatFields === void 0 ? void 0 : formatFields.includes(k)) {
                        datum[k] = fields[k].normalizer(datum[k]);
                    }
                });
                if (datum.contacts && typeof datum.contacts === 'string') {
                    datum.contacts = datum.contacts.split(',');
                }
                if (datum.commission_rate) {
                    datum.new_commission_rate =
                        tool.convertToNumber(datum.commission_rate) / 100;
                }
                if (datum.carrier_rate) {
                    datum.new_carrier_rate =
                        tool.convertToNumber(datum.carrier_rate) / 100;
                }
                if (datum.tags != null && !Array.isArray(datum.tags)) {
                    datum.tags = [datum.tags];
                }
                return Object.assign(Object.assign({}, datum), { type: fileType, document_id: rowData.str_id, import_id: importId });
            });
            const resList = [];
            if (fileType === 'statement') {
                for (let i = 0; i < normalizedData.length; i += 1000) {
                    const chunk = normalizedData.slice(i, i + 1000);
                    const res = yield statementDataPoster.mutateAsync(chunk);
                    resList.push(res);
                    if (res.error) {
                        setErrors(Object.assign(Object.assign({}, errors), { upload: `Error uploading data (${res.error})` }));
                        Sentry.captureException(res.error);
                        return null;
                    }
                }
                // Await statementDataPoster.mutateAsync(dataArray);
            }
            else if (fileType === 'report') {
                for (let i = 0; i < normalizedData.length; i += 1000) {
                    const chunk = normalizedData.slice(i, i + 1000);
                    const res = yield reportDataPoster.mutateAsync(chunk);
                    resList.push(res);
                    if (res.error) {
                        setErrors(Object.assign(Object.assign({}, errors), { upload: `Error uploading data (${res.error})` }));
                        Sentry.captureException(res.error);
                        return null;
                    }
                }
            }
            else {
                throw new Error(`Unsupported file type: ${fileType}`);
            }
            const filename = rowData.override_filename || rowData.filename;
            const notes = fileType === 'statement' ? 'New statement data' : 'New report data';
            const allSuccess = resList.every((res) => res.status === 'OK');
            const processDuration = (((_b = timerCountRef.current) === null || _b === void 0 ? void 0 : _b.totalSeconds) || 0) * 1000;
            const processingLogParams = {
                type: 'document_processing',
                duration: processDuration,
                status: allSuccess ? 'completed' : 'failed',
                params: filename,
                notes,
                stats: {
                    count: normalizedData.length,
                },
            };
            const metadata = {
                status: allSuccess ? 'Success' : 'Failed',
                count: normalizedData.length,
            };
            const importsData = {
                process_duration: processDuration,
                summed_total_amount: processFormatData.cmsTotal,
                count: normalizedData.length,
                type: fileType,
                document_str_id: rowData.str_id,
                company_str_id: (_c = rowData === null || rowData === void 0 ? void 0 : rowData.companies) === null || _c === void 0 ? void 0 : _c.str_id,
                str_id: importId,
                metadata,
                status: allSuccess ? 'Success' : 'Failed',
                feedback: feedback,
                process_count: actionCount,
                process_action_records: Object.keys(processActionMap).length
                    ? processActionMap
                    : null,
                processor_str_id: processForm.processor || null,
                profile_str_id: rowData.profile_str_id || null,
            };
            const params = {
                id: rowData.id,
                company_str_id: (_d = rowData === null || rowData === void 0 ? void 0 : rowData.companies) === null || _d === void 0 ? void 0 : _d.str_id,
                mapping,
                processor: processForm.processor,
                prompt: processForm.prompt,
                type: fileType,
                method: processForm.method,
                state: 'active',
                status: 'processed',
                import_id: importId,
                imported_at: new Date().toISOString(),
                processing_log: processingLogParams,
                imports_log: importsData,
            };
            const resp = yield documentsPatcher.mutateAsync(params);
            if (resp.error) {
                setErrors(Object.assign(Object.assign({}, errors), { upload: `Error uploading data (${resp.error})` }));
                Sentry.captureException(resp.error);
                return null;
            }
            return normalizedData;
        }
        catch (e) {
            setErrors(Object.assign(Object.assign({}, errors), { upload: `Error uploading data (${e.message})` }));
            Sentry.captureException(e);
        }
    });
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (errors.missingField && !((_a = tipRef.current) === null || _a === void 0 ? void 0 : _a.ignoreErrors)) {
            return {
                error: errors.missingField,
            };
        }
        const mappingStrId = yield uploadMapping();
        if (!mappingStrId) {
            return;
        }
        const res = yield bulkUpload({ mapping: mappingStrId });
        setExtraction('');
        setFile(null);
        return !!res;
    });
    //* ****************************Upload mapping end************************************/
    const onDragFinished = (size) => {
        setPreviewWidth(size[0]);
    };
    useImperativeHandle(ref, () => ({
        submit,
    }));
    return (_jsx(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
            height: '100%',
        }, children: _jsx(Box, { sx: { flex: 1 }, children: _jsxs(Allotment, { onDragEnd: onDragFinished, className: "flex flex-1 h-full", defaultSizes: [25, 75], children: [_jsx(Allotment.Pane, { children: previewWidth ? (_jsx(Box, { sx: {
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }, children: _jsx(Box, { sx: { flex: 1, overflow: 'auto' }, children: _jsx(ProcessPreview, { previewWidth: previewWidth, rowData: rowData, processForm: processForm }) }) })) : null }), _jsx(Allotment.Pane, { className: "h-full flex flex-1", children: _jsxs(Box, { sx: {
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'auto',
                                height: '100%',
                                ml: 2,
                            }, children: [_jsx(Box, { sx: {
                                        flex: 1,
                                        overflow: 'auto',
                                    }, children: _jsx(CommissionMapper, { errors: errors, setErrors: setErrors, file: file, fileType: fileType, mpData: mpData, rowData: rowData, processForm: processForm, setProcessForm: setProcessForm, extraction: extractTableRaw, selectedSheet: selectedSheet, setSelectedSheet: setSelectedSheet, spreadsheet: spreadsheet, setProcessFormatData: setProcessFormatData, processFormatData: processFormatData, fileData: fileData, addActionCount: addActionCount }) }), _jsx(Box, { children: _jsx(StickyTips, { tipsMap: errors, data: processFormatData, ref: tipRef }) })] }) })] }) }) }));
};
export default forwardRef(ProcessUploads);
