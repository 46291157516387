import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Chip } from '@mui/material';
import { CustomerType } from 'common/customer/customer.constants';
import { useMemo } from 'react';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import { DedupeValue } from '../constants';
export const useNewCustomerColumns = ({ setCustomersType, setGroupIds, setCustomerNames, customerNames, dedupeBy, }) => {
    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'dedupe_customers_names',
                header: 'Customer name (Deduped by)',
                visibleInShowHideMenu: dedupeBy === DedupeValue.GroupId,
                Cell: ({ row }) => {
                    var _a;
                    if ((((_a = row.original.dedupe_customers_names) === null || _a === void 0 ? void 0 : _a.length) || 0) > 1) {
                        return (_jsx(EnhancedSelect, { options: row.original.dedupe_customers_names || [], value: customerNames[row.original.clientId] ||
                                row.original.company_name ||
                                `${row.original.first_name} ${row.original.last_name}`, onChange: (v) => {
                                setCustomerNames((prev) => (Object.assign(Object.assign({}, prev), { [row.original.clientId]: v })));
                            } }));
                    }
                    return null;
                },
            },
            {
                accessorKey: 'first_name',
                header: 'First name',
                Cell: ({ renderedCellValue, row }) => {
                    if (row.original.customer_id && renderedCellValue) {
                        return _jsx(Chip, { label: renderedCellValue, size: "small" });
                    }
                    return renderedCellValue;
                },
            },
            {
                accessorKey: 'last_name',
                header: 'Last name',
                Cell: ({ renderedCellValue, row }) => {
                    if (row.original.customer_id && renderedCellValue) {
                        return _jsx(Chip, { label: renderedCellValue, size: "small" });
                    }
                    return renderedCellValue;
                },
            },
            {
                accessorKey: 'company_name',
                header: 'Company name',
                Cell: ({ renderedCellValue, row }) => {
                    if (row.original.customer_id && renderedCellValue) {
                        return _jsx(Chip, { label: renderedCellValue, size: "small" });
                    }
                    return renderedCellValue;
                },
            },
            {
                accessorKey: 'type',
                header: 'Type',
                Cell: ({ renderedCellValue, row }) => {
                    return (_jsx(EnhancedSelect, { options: [CustomerType.individual, CustomerType.group], value: renderedCellValue, onChange: (v) => {
                            setCustomersType((prev) => (Object.assign(Object.assign({}, prev), { [row.original.clientId]: v })));
                        } }));
                },
            },
            {
                accessorKey: 'group_id',
                header: 'Group ID',
                Cell: ({ renderedCellValue, row }) => {
                    if (row.original.dedupe_group_ids &&
                        row.original.dedupe_group_ids.length > 1) {
                        return (_jsx(EnhancedSelect, { options: row.original.dedupe_group_ids, value: renderedCellValue, onChange: (v) => {
                                setGroupIds((prev) => (Object.assign(Object.assign({}, prev), { [row.original.clientId]: v })));
                            } }));
                    }
                    return renderedCellValue;
                },
            },
            {
                accessorKey: 'policies_ids',
                header: 'Policies',
                Cell: ({ renderedCellValue }) => {
                    return (_jsx(Box, { sx: { whiteSpace: 'wrap' }, children: renderedCellValue === null || renderedCellValue === void 0 ? void 0 : renderedCellValue.join(', ') }));
                },
            },
            {
                accessorKey: 'internal_id',
                header: 'Internal ID',
            },
        ];
    }, [
        customerNames,
        dedupeBy,
        setCustomerNames,
        setCustomersType,
        setGroupIds,
    ]);
    return { columns };
};
