import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, MRT_ToggleFullScreenButton, } from 'material-react-table';
import { useMemo } from 'react';
import { GROUP_BY_KEY, GroupViewOptions, GroupViewValues, } from 'common/documents/documents.constants';
import { Box, Link, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useGroupData } from '../apis';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import { useProcessGroupBy } from '../hooks/useProcessGroupBy';
import { formatDateName, getStartAndEndDates } from '../utils';
import { ROUTES } from '../../constants';
export const DocumentsGroupView = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const groupBy = GroupViewOptions.find((item) => { var _a; return item.id === ((_a = searchParams.get(GROUP_BY_KEY)) !== null && _a !== void 0 ? _a : GroupViewValues.MONTH); });
    const { data = [], isLoading } = useGroupData();
    const groupedData = useProcessGroupBy({
        data,
        groupBy: (groupBy === null || groupBy === void 0 ? void 0 : groupBy.id) || GroupViewValues.MONTH,
    });
    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'date',
                header: 'Date',
                Cell: ({ renderedCellValue }) => {
                    const { startDate, endDate } = getStartAndEndDates(renderedCellValue, (groupBy === null || groupBy === void 0 ? void 0 : groupBy.id) || GroupViewValues.MONTH);
                    const link = `${ROUTES.documents}?start_date=${startDate}&end_date=${endDate}`;
                    return (_jsxs(Link, { href: link, target: "_blank", underline: "none", sx: {
                            color: 'black',
                            display: 'flex',
                            alignItems: 'center',
                            ':hover': {
                                '& .MuiSvgIcon-root': {
                                    visibility: 'visible',
                                },
                            },
                        }, children: [formatDateName(renderedCellValue, (groupBy === null || groupBy === void 0 ? void 0 : groupBy.id) || GroupViewValues.MONTH), _jsx(OpenInNewIcon, { sx: { visibility: 'hidden', ml: 1, fontSize: 14 } })] }));
                },
            },
            {
                accessorKey: 'file_count',
                header: 'Documents',
            },
        ];
    }, [groupBy === null || groupBy === void 0 ? void 0 : groupBy.id]);
    return (_jsx(MaterialReactTable, { enableRowVirtualization: true, enableStickyHeader: true, getRowId: (v) => v.date, columns: columns, data: groupedData, enableGlobalFilter: false, initialState: {
            density: 'compact',
        }, state: {
            isLoading,
        }, muiTablePaperProps: {
            sx: {
                pt: 2,
                width: '100%',
                height: '100%',
            },
        }, renderTopToolbar: ({ table }) => {
            return (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', pr: 2, pl: 1, pb: 1 }, children: [_jsx(Typography, { variant: "h5", children: "Documents groups" }), _jsxs(Box, { sx: { ml: 'auto', display: 'flex', alignItems: 'center' }, children: [_jsx(EnhancedSelect, { options: GroupViewOptions, label: "Group by", value: groupBy, onChange: (v) => {
                                    setSearchParams({ [GROUP_BY_KEY]: v.id });
                                } }), _jsx(MRT_ShowHideColumnsButton, { table: table }), _jsx(MRT_ToggleDensePaddingButton, { table: table }), _jsx(MRT_ToggleFullScreenButton, { table: table })] })] }));
        } }));
};
